import { useFavorites } from '@hultafors/toeguard/hooks';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Product } from '../Product/Product';
import { SlideIn } from '../SlideIn/SlideIn';

import { FavoritesStyled } from './Favorites.styled';

interface FavoritesProps {
  className?: string;
  style?: React.CSSProperties;
  isOpen: boolean;
  toggleMenu: (...args: any[]) => any;
  header: string;
  clearText: string;
  badgeText: string;
  rrpLabel?: string;
  favoritesEmpty?: string;
  isSmall?: boolean;
}
export const Favorites: React.FC<FavoritesProps> = ({
  className,
  style,
  toggleMenu,
  isOpen,
  header,
  clearText,
  badgeText,
  rrpLabel,
  favoritesEmpty,
}) => {
  const { favorites, deleteFavorites } = useFavorites();
  return (
    <FavoritesStyled className={className} style={style} data-test="Favorites">
      <SlideIn
        toggle={toggleMenu}
        isOpen={isOpen}
        headerText={`${header}`}
        clearText={clearText}
        clearAll={deleteFavorites}
        padContent
        fromLeft={false}
        itemLength={favorites.length}
      >
        <Grid
          columns={[{ columns: 1 }, { breakpoint: 'mobileMax', columns: 1 }]}
        >
          <>
            {favorites.map((product, i) => {
              return (
                <GridChild
                  key={`Favorite-${i}`}
                  columnSpan={[
                    { columns: 1 },
                    { breakpoint: 'mobileMax', columns: 1 },
                  ]}
                >
                  <Product
                    product={product}
                    isFavorite
                    inFavorites={true}
                    badgeText={badgeText}
                    rrpLabel={rrpLabel}
                    noHover
                    toggleMenu={toggleMenu}
                  />
                </GridChild>
              );
            })}
            {!favorites?.length && (
              <HTag center type="h3" styleType="header5">
                {favoritesEmpty}
              </HTag>
            )}
          </>
        </Grid>
      </SlideIn>
    </FavoritesStyled>
  );
};
