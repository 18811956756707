import { StyledH1 } from './H1.styled';

interface H1Props {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  center?: boolean;
}
export const H1: React.FC<H1Props> = ({
  className,
  style,
  children,
  center,
}) => {
  return (
    <StyledH1 className={className} style={style} $center={center}>
      {children}
    </StyledH1>
  );
};
