import { ImageFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Image } from '../Image/Image';

import { StyledDualImage } from './DualImage.styled';

interface DualImageProps {
  imageOne?: ImageFragment | null;
  imageTwo?: ImageFragment | null;
}
export const DualImage: React.FC<DualImageProps> = (props) => {
  const { imageOne, imageTwo } = props;
  return (
    <StyledDualImage>
      <Grid rowGap={16} columnGap={48}>
        <GridChild
          className="ImageGridChild"
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 3 },
            { breakpoint: 'desktop', columns: 3, start: 4 },
          ]}
        >
          {imageOne?.url && (
            <Image src={`${imageOne.url}?w=900`} alt={imageOne.alt ?? ''} />
          )}
        </GridChild>
        <GridChild
          className="ImageGridChild"
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 4, start: 7 },
            { breakpoint: 'desktop', columns: 3, start: 7 },
          ]}
        >
          {imageTwo?.url && (
            <Image src={`${imageTwo.url}?w=900`} alt={imageTwo.alt ?? ''} />
          )}
        </GridChild>
      </Grid>
    </StyledDualImage>
  );
};
