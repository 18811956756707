import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

import { ExploreMoreSectionStyled } from './ExploreMoreSection.styled';

interface ExploreMoreSectionProps {
  title?: string;
  className?: string;
  children?: React.ReactNode;
}

export const ExploreMoreSection: React.FC<ExploreMoreSectionProps> = ({
  title,
  children,
  className,
}) => {
  if (!children) {
    return null;
  }
  return (
    <ExploreMoreSectionStyled className={className}>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 1 },
          ]}
        >
          {title && (
            <HTag
              type="h2"
              styleType={[
                { styleType: 'header3' },
                { breakpoint: 'mobileMax', styleType: 'header2' },
              ]}
            >
              {title}
            </HTag>
          )}
        </GridChild>
      </Grid>

      {children}
    </ExploreMoreSectionStyled>
  );
};
