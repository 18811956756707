import { ArrowLink } from '../ArrowLink/ArrowLink';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';
import { Section } from '../Section/Section';

import { ContentBlockStyled } from './ContentBlock.styled';
interface ContentBlockProps {
  title: string;
  description?: string;
  linkText?: string;
  linkUrl?: string;
}
export const ContentBlock: React.FC<ContentBlockProps> = ({
  title,
  description,
  linkUrl,
  linkText,
}) => {
  return (
    <ContentBlockStyled title={title}>
      <Section className="HeroContent">
        <Grid columnGap={48}>
          <GridChild
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 12, start: 1 },
              { breakpoint: 'desktop', columns: 6, start: 1 },
              { breakpoint: 'desktopLarge', columns: 5, start: 2 },
            ]}
          >
            <HTag
              type="h2"
              styleType={[
                { styleType: 'header3' },
                { breakpoint: 'desktop', styleType: 'header1' },
              ]}
            >
              {title}
            </HTag>
          </GridChild>
          {description && (
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 12, start: 1 },
                { breakpoint: 'desktop', columns: 6, start: 7 },
                { breakpoint: 'desktopLarge', columns: 5, start: 7 },
              ]}
            >
              <Paragraph styleType="body16" className="TextBoxText">
                {description}
              </Paragraph>
              {linkText && linkUrl && (
                <ArrowLink title={linkText} url={linkUrl} />
              )}
            </GridChild>
          )}
        </Grid>
      </Section>
    </ContentBlockStyled>
  );
};
