import Link from 'next/link';

import {
  MenuItemFragment,
  SubMenuItemFragment,
} from '@hultafors/toeguard/types';

import { SlideIn } from '../SlideIn/SlideIn';

import {
  Chevron,
  Flex,
  MenuItem,
  MenuStyled,
  PaddingWrapper,
  PartnerLink,
  PopMenu,
  SubMenuItem,
} from './Menu.styled';
interface MenuProps {
  toggleMenu: (...args: any[]) => any;
  headerText: string;
  menu?: MenuItemFragment[];
  menuType?: string;
  urlToPartnerPortal: string;
  partnerLoginLabel: string;
  isOpen: boolean;
  subMenuShowing?: string;
  setSubMenuShowing?: React.Dispatch<React.SetStateAction<string>>;
}
export const Menu: React.FC<MenuProps> = ({
  menu = [],
  subMenuShowing,
  setSubMenuShowing,
  ...props
}) => {
  const menuMapper = (menuItem: MenuItemFragment, index: number) => {
    if (!menuItem.submenu) {
      return (
        <MenuItem
          key={`menu-item-${index}`}
          href={menuItem.url ?? ''}
          as={Link}
          onClick={() => props.toggleMenu('mobileMenu')}
        >
          {menuItem.title}
        </MenuItem>
      );
    }
    const subMenuMapper = (
      subMenuItem: SubMenuItemFragment,
      subIndex: number,
    ) => {
      if (!subMenuItem.pageLink?.slug) {
        return null;
      }
      return (
        <SubMenuItem
          key={`submenu-item-${subMenuItem.title}-${subIndex}`}
          href={`/guides/${subMenuItem.pageLink.slug}`}
          onClick={() => props.toggleMenu('mobileMenu')}
        >
          {subMenuItem.title}
        </SubMenuItem>
      );
    };
    const onClick = () => {
      setSubMenuShowing?.(
        menuItem?.submenu?.title && subMenuShowing === menuItem.submenu.title
          ? ''
          : menuItem.submenu?.title ?? '',
      );
    };
    return (
      <MenuItem key={`menu-item-${index}`} onClick={onClick}>
        {menuItem.title}
        <Chevron
          $open={subMenuShowing === menuItem.submenu.title}
          src="/assets/gfx/arrow_down_16.svg"
          alt="Arrow down"
          width={16}
          height={30}
        />
        <PopMenu
          $items={menuItem.submenu.menuItems.length}
          $open={subMenuShowing === menuItem.submenu.title}
        >
          <PaddingWrapper>
            {menuItem.submenu.menuItems.map(subMenuMapper)}
          </PaddingWrapper>
        </PopMenu>
      </MenuItem>
    );
  };
  return (
    <MenuStyled>
      <SlideIn
        toggle={props.toggleMenu}
        isOpen={props.isOpen}
        isMenu
        isFilter
        padContent
      >
        <Flex>{menu?.map(menuMapper)}</Flex>
        <PartnerLink
          href={props.urlToPartnerPortal}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.partnerLoginLabel}
          <img
            src="/assets/gfx/partner_login_16.svg"
            alt="Partner portal login"
            title="Partner portal login"
            width={16}
            height={16}
          />
        </PartnerLink>
      </SlideIn>
    </MenuStyled>
  );
};
