import { EmptyProductStyled } from './EmptyProduct.styled';
export const EmptyProduct: React.FC = () => {
  return (
    <EmptyProductStyled>
      <div className="ImagePlaceholder" />
      <div className="ProductInfoPlaceholder">
        <span />
        <span />
      </div>
    </EmptyProductStyled>
  );
};
