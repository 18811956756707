import { createContext, useEffect, useState } from 'react';

import {
  getFavorites,
  removeFavorites,
  toggleFavorite,
} from '@hultafors/toeguard/helpers';

interface FavoritesContextValue {
  favorites: any[];
  toggleItem(product: any, isFavorite?: boolean): void;
  deleteFavorites(): void;
}
export const FavoritesContext = createContext<FavoritesContextValue | null>(
  null,
);

interface FavoritesProviderProps {
  children?: React.ReactNode;
}

export const FavoritesProvider: React.FC<FavoritesProviderProps> = ({
  children,
}) => {
  const [favorites, setFavorites] = useState<any[]>([]);
  const toggleItem = (product: any, isFavorite?: boolean) => {
    const result = toggleFavorite(product, isFavorite);
    setFavorites(result);
  };
  const deleteFavorites = () => {
    setFavorites([]);
    removeFavorites();
  };
  useEffect(() => {
    const result = getFavorites();
    if (result) {
      setFavorites(result);
    }
  }, []);
  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        toggleItem,
        deleteFavorites: deleteFavorites,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
