import { ProductInformationTabs } from '../ProductInformationTabs/ProductInformationTabs';

import { ProductDetailInformationStyled } from './ProductDetailInformation.styled';
interface ProductDetailInformationProps {
  usp?: string[];
  overviewLabel?: string;
  documentsLabel?: string;
  detailsLabel?: string;
  intro?: string;
  detailsInfo?: string;
  documents?: object[];
  products?: object;
  defaultImage?: any;
  product?: any;
}
export const ProductDetailInformation: React.FC<
  ProductDetailInformationProps
> = ({
  usp,
  overviewLabel,
  documentsLabel,
  detailsLabel,
  intro,
  documents,
  detailsInfo,
  product,
}) => {
  const renderFeatures = () => {
    if (!usp?.length) {
      return null;
    }
    return (
      <div className="FeatureList">
        <ul>
          {usp.map((item, i) => (
            <li key={`uspKey${i}`}>{item}</li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <ProductDetailInformationStyled>
      {renderFeatures()}
      <ProductInformationTabs
        product={product}
        overviewLabel={overviewLabel}
        documentsLabel={documentsLabel}
        detailsLabel={detailsLabel}
        intro={intro}
        detailsInfo={detailsInfo}
        documents={documents}
      />
    </ProductDetailInformationStyled>
  );
};
