import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

export const SeoSectionStyled = styled.div`
  border-top: 1px solid ${colors.gray4};
  padding: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.large} 0;
  }

  a,
  p {
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};
  }

  .Title {
    padding-bottom: ${spacing.medium};
  }
`;

export const BottomSpacingStyled = styled.div`
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-bottom: ${spacing.large};
  }
`;
