import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
const desktopMenuHeight = 600;

interface SlideInStyledProps {
  $fromTop?: boolean;
  $fromLeft?: boolean;
  $small?: boolean;
  $isSearch?: boolean;
  $isFilter?: boolean;
  $padContent?: boolean;
}

export const SlideInStyled = styled.div<SlideInStyledProps>`
  .SlideInBackground {
    width: 100%;
    height: 0;
    position: fixed;
    left: 0;
    z-index: -1;
    overflow-x: hidden;
    background-color: rgb(35 33 32 / 30%);
    opacity: 0;
    transition: opacity 0.3s, z-index 0.1s 0.3s;
    top: ${({ $fromTop }) => ($fromTop ? spacing.menuHeight : 0)};

    &.active {
      height: 100%;
      z-index: ${({ $fromTop }) => ($fromTop ? '9' : '10')};
      opacity: 1;
      transition: opacity 0.3s, z-index 0.1s;
    }
  }

  .SlideInInside {
    position: absolute;
    top: 0;
    right: ${({ $fromLeft }) => ($fromLeft ? 'auto' : 0)};
    left: ${({ $fromLeft }) => ($fromLeft ? 0 : 'auto')};
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 12;
    box-sizing: border-box;
    background-color: ${colors.white};
    transform: ${({ $fromTop, $fromLeft }) =>
      $fromTop
        ? 'translateY(-110%)'
        : $fromLeft
        ? 'translateX(-110%)'
        : 'translateX(110%)'};
    transition: transform 0.3s ease-in-out;

    &.active {
      transform: ${({ $small, $isSearch, $fromTop }) =>
        $small && !$isSearch
          ? 'translateY(-61%)'
          : $fromTop
          ? 'translateY(0%)'
          : 'translateX(0%)'};
      transition: transform 0.3s ease-in-out;
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      width: 100%;
      max-width: ${({ $fromTop }) => ($fromTop ? 'none' : '400px')};
      max-height: ${({ $fromTop, $isSearch }) =>
        $fromTop
          ? $isSearch
            ? 152 + 'px'
            : desktopMenuHeight + 'px'
          : 'none'};
      height: ${({ $fromTop, $isSearch }) =>
        $fromTop && !$isSearch ? 'auto' : 'inherit'};
    }
    ${({ $isFilter, $fromTop }) =>
      $isFilter
      && css`
        @media screen and (min-width: ${breakpoints.mobileMax}) {
          max-width: ${$fromTop ? 'none' : '477px'};
        }
      `}

    .SlideInHeader .CloseBtn {
      display: flex;
      width: ${spacing.regular};
      height: 100%;
      cursor: pointer;
      outline: none;
      border: none;
      background: transparent;

      svg {
        width: ${spacing.regular};
        height: ${spacing.regular};

        path {
          transition: 0.2s ease;
        }
      }
    }

    .SlideInHeader .CloseBtn:hover {
      svg path {
        fill: ${colors.blackHover};
      }
    }

    .SlideInHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 56px;
      min-height: 56px;
      padding: 0
        ${({ $isFilter }) => ($isFilter ? spacing.regular : spacing.small)};
      border-bottom: ${({ $isFilter }) =>
        $isFilter ? 'none' : `1px solid ${colors.gray4}`};

      .HeaderText {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: ${fontSizes.header3};
          line-height: ${lineHeights.header3};
          color: ${colors.black};
        }

        button {
          overflow: visible;
          text-transform: none;
          border: none;
          appearance: none;
          display: flex;
          align-items: center;
          outline: none;
          background: none;
          padding-left: 0;
        }

        .HeaderIcon {
          width: ${spacing.regular};
          height: ${spacing.regular};
          display: inline-flex;
          margin-right: ${spacing.xsmall};

          @media screen and (min-width: ${breakpoints.mobile}) {
            margin-right: ${spacing.small};
          }
        }

        .Extras {
          margin-left: ${spacing.xsmall};
          color: ${colors.hellSalmon};
        }
      }

      .SlideInExit {
        display: flex;
        align-items: center;

        .Clear {
          display: flex;
          align-items: center;
          margin-right: ${spacing.small};
          font-size: ${fontSizes.body};
          line-height: ${lineHeights.bodyXS};
          cursor: pointer;
          transition: 0.2s ease;
          background-color: transparent;
          appearance: none;
          border: 0;

          .RemoveFilterIcon {
            width: ${spacing.small};
            height: ${spacing.small};
            margin-left: ${spacing.xsmall};
          }
        }

        span {
          font-size: ${fontSizes.s};
          letter-spacing: 0.5px; /* 0.5px */
        }
      }

      .SlideInExit .Clear:hover {
        color: ${colors.blackHover};

        svg path {
          fill: ${colors.blackHover};
        }
      }
    }

    &.search {
      .SlideInHeader {
        display: none;
      }
    }

    .SlideInBody {
      padding: ${({ $padContent }) =>
        $padContent
          ? `${spacing.regular} ${spacing.small} ${spacing.medium} ${spacing.small}`
          : ''};
      overflow: ${({ $fromTop }) => ($fromTop ? 'visible' : 'scroll')};
      height: ${({ $fromTop, $isSearch }) =>
        $fromTop && !$isSearch ? 'auto' : '100%'};
      width: 100%;
      box-sizing: border-box;

      &.isCart {
        padding: 0 ${spacing.small} 150px ${spacing.small};
      }
    }
  }
`;
