import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';

import { BottomSpacingStyled, SeoSectionStyled } from './SeoSection.styled';
interface SeoSectionProps {
  title?: string;
  description?: string;
}
export const SeoSection: React.FC<SeoSectionProps> = ({
  title,
  description,
}) => {
  if (!title && !description) {
    return <BottomSpacingStyled />;
  }
  return (
    <SeoSectionStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 5, start: 2 },
          ]}
        >
          <HTag
            className="Title"
            type="h2"
            styleType={[
              { styleType: 'header3' },
              { breakpoint: 'desktop', styleType: 'header1' },
            ]}
          >
            {title}
          </HTag>

          <Markdown options={{ forceBlock: true }}>{description}</Markdown>
        </GridChild>
      </Grid>
    </SeoSectionStyled>
  );
};
