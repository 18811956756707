import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';
import { buttonReset } from '@hultafors/shared/helpers';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

export const NavContainer = styled.nav`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: ${spacing.menuHeight};
  align-items: center;
  width: 100%;
  z-index: 100;
`;

export const TransformWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 ${spacing.small};
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray2};
  position: relative;
  z-index: 2;

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    padding: 0 ${spacing.medium};
  }
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
`;

interface ActiveProps {
  $active?: boolean;
}

const menuItemStyles = css<ActiveProps>`
  height: 100%;
  font-size: ${fontSizes.body14};
  font-weight: ${fontWeights.fontMedium};
  display: flex;
  align-items: center;
  border-top: thin solid transparent;
  border-bottom: thin solid transparent;
  cursor: pointer;
  box-sizing: border-box;
  ${({ $active }) =>
    $active
    && css`
      border-bottom-color: ${colors.black};
    `}
`;

export const MenuItem = styled(Link)<ActiveProps>`
  ${menuItemStyles};
`;

export const MenuItemButton = styled.button<ActiveProps>`
  ${buttonReset};
  ${menuItemStyles};
`;

export const Flex = styled.div<{ $desktop?: boolean }>`
  align-items: center;
  height: 100%;
  display: ${({ $desktop }) => ($desktop ? 'none' : 'flex')};
  gap: ${spacing.small};

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    display: ${({ $desktop }) => ($desktop ? 'flex' : null)};
  }
`;

export const MenuIcon = styled.img`
  width: 24px;
  margin-right: ${spacing.small};
  cursor: pointer;
  object-fit: cover;

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: 24px;
  width: auto;

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    margin-right: ${spacing.medium};
  }
`;

export const Backdrop = styled.div<{ $show?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s;
  pointer-events: none;
  ${({ $show }) =>
    $show
    && css`
      background-color: rgb(0 0 0 / 30%);
    `}
`;

export const MenuDrop = styled.div<{ $show?: boolean }>`
  width: 100%;
  display: none;
  pointer-events: inherit;

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    display: initial;
    pointer-events: none;
    visibility: hidden;
    transition: 0.2s;
    transform: translateY(-100%);
    transform-origin: 0% 0%;
    background-color: ${colors.white};
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 0;
    padding: ${spacing.medium};
    ${({ $show }) =>
      $show
      && css`
        pointer-events: initial;
        visibility: initial;
        transition: transform 0.2s, visibility 0s;
        display: initial;
        transform: translateY(0%);
      `}
  }
`;

export const InnerDrop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${breakpoints.maxPageWidth};
  margin: auto;
`;

export const SubMenuItem = styled(Link)`
  width: auto;
  font-size: ${fontSizes.header5};
  font-weight: ${fontWeights.fontBold};
  margin-bottom: ${spacing.small};
  cursor: pointer;
`;

export const SecondaryNav = styled.nav<{ $desktop?: boolean }>`
  align-items: center;
  height: 100%;
  display: ${({ $desktop }) => ($desktop ? 'none' : 'flex')};

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    display: ${({ $desktop }) => ($desktop ? 'flex' : null)};
  }

  > * + * {
    margin-left: ${spacing.small};
  }
`;

export const IconItem = styled.img<{ $desktop?: boolean }>`
  display: ${({ $desktop }) => $desktop && 'none'};
  cursor: pointer;

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    display: initial;
  }
`;

export const IconLink = styled(Link)`
  display: none;
  cursor: pointer;

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    display: initial;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
`;

export const NavButton = styled.button<{ $desktop?: boolean }>`
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  display: ${({ $desktop }) => $desktop && 'none'};

  @media all and (min-width: ${breakpoints.desktopSmall}) {
    display: block;
  }
`;
