import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Markdown } from '../Markdown/Markdown';

import { StyledInformationListHero } from './InformationListHero.styled';

interface DescriptionRendererProps {
  children: React.ReactNode;
}
const DescriptionRenderer: React.FC<DescriptionRendererProps> = ({
  children,
  ...rest
}) => (
  <HTag type="h2" styleType="header4" {...rest}>
    {children}
  </HTag>
);

interface InformationListHeroProps {
  title: string;
  description: string;
  className?: string;
}
export const InformationListHero: React.FC<InformationListHeroProps> = ({
  title,
  description,
  className,
}) => {
  if (!title && !description) {
    return null;
  }
  return (
    <StyledInformationListHero className={className}>
      <Grid columnGap={48}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 1 },
            { breakpoint: 'desktop', columns: 6, start: 1 },
            { breakpoint: 'desktopLarge', columns: 5, start: 1 },
          ]}
        >
          <div className="HeroText">
            {title && (
              <HTag
                type="h1"
                styleType={[
                  { styleType: 'header3' },
                  { breakpoint: 'mobileMax', styleType: 'header2' },
                ]}
              >
                {title}
              </HTag>
            )}
            {description && (
              <Markdown
                options={{
                  overrides: {
                    span: DescriptionRenderer,
                  },
                }}
              >
                {description}
              </Markdown>
            )}
          </div>
        </GridChild>
      </Grid>
    </StyledInformationListHero>
  );
};
