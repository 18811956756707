import { ImageFragment } from '@hultafors/toeguard/types';

import { Button } from '../Button/Button';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

import { HeroStyled } from './Hero.styled';

interface HeroProps {
  title?: string | null;
  image?: ImageFragment | null;
  imageMobile?: ImageFragment | null;
  heroCtaTitle1?: string | null;
  heroCtaUrl1?: string | null;
  heroCtaTitle2?: string | null;
  heroCtaUrl2?: string | null;
  className?: string;
}
export const Hero: React.FC<HeroProps> = ({
  title,
  heroCtaTitle1,
  heroCtaUrl1,
  heroCtaTitle2,
  heroCtaUrl2,
  className,
  image,
  imageMobile,
}) => {
  return (
    <HeroStyled className={className} $image={image} $imageMobile={imageMobile}>
      <div className="ImageWrapper">
        <div className={`HeroContent ${className}`}>
          <Grid style={{ width: '100%' }}>
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 10, start: 2 },
              ]}
            >
              <div className="CenterWrapper">
                <HTag
                  className="Title"
                  type="h1"
                  styleType={[
                    { styleType: 'header2' },
                    { breakpoint: 'mobileMax', styleType: 'mega' },
                  ]}
                >
                  {title}
                </HTag>
                {(heroCtaTitle1 || heroCtaTitle2) && (
                  <div className="HeroButtonWrapper">
                    <span className="InnerButtonWrapper">
                      {!!heroCtaTitle1 && (
                        <Button to={heroCtaUrl1} variant="primary">
                          {heroCtaTitle1}
                        </Button>
                      )}
                      {heroCtaTitle2 && (
                        <Button to={heroCtaUrl2} variant="secondary">
                          {heroCtaTitle2}
                        </Button>
                      )}
                    </span>
                  </div>
                )}
              </div>
            </GridChild>
          </Grid>
        </div>
      </div>
    </HeroStyled>
  );
};
