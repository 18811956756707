import { ToeguardProduct } from '@hultafors/toeguard/api';
import { colors } from '@hultafors/toeguard/helpers';
import { createDetailPageUrl } from '@hultafors/toeguard/helpers';

import { Image } from '../Image/Image';
import { RouterLink } from '../RouterLink/RouterLink';

import { ProductImageStyled } from './ProductImage.styled';
interface ProductImageProps {
  product: ToeguardProduct;
  onClick?: (...args: any[]) => any;
  routerState?: object;
}
export const ProductImage: React.FC<ProductImageProps> = (props) => {
  const canRenderImage = () => {
    return props.product && props.product.image && props.product.image.url;
  };
  const formatColor = (color: string) => {
    return color.substring(1, color.length);
  };
  return (
    <ProductImageStyled>
      <RouterLink
        className="ImageWrapper"
        to={createDetailPageUrl(props.product)}
        routerState={props.routerState}
        onClick={props.onClick}
      >
        {canRenderImage()
          ? (
            <Image
              src={`${props.product.image.url}?w=500&bgcolor=${formatColor(
              colors.gray6,
            )}&format=jpg`}
              alt={props.product.name}
            />
            )
          : (
            <Image src="/assets/gfx/noImage.png" alt={props.product.name} />
            )}
      </RouterLink>
    </ProductImageStyled>
  );
};
