import styled, { css } from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';

export type TextButtonVariant = 'black' | 'white';

interface TextButtonStyledProps {
  $variant?: TextButtonVariant;
  $small?: boolean;
  $iconSize?: number;
  $icon?: boolean;
  $arrow?: boolean;
  $clean?: boolean;
  $isLoading?: boolean;
}

export const TextButtonStyled = styled.button<TextButtonStyledProps>`
  /* Reset */
  appearance: none;
  border: none;
  background-color: transparent;
  outline: none;

  /* Styles */
  display: inline-flex;
  align-items: center;
  height: ${spacing.medium};
  color: ${({ $variant }) =>
    $variant === 'black' ? colors.black : colors.white};
  text-decoration: none;
  padding: ${spacing.xsmall} 0;
  cursor: pointer;
  transition: 0.2s;

  /* Text style */
  font-size: ${({ $small }) => ($small ? fontSizes.body14 : fontSizes.body16)};
  line-height: ${({ $small }) =>
    $small ? lineHeights.body14 : lineHeights.body16};
  font-weight: ${fontWeights.fontRegular};

  /* In case of Icon */
  svg {
    width: ${({ $iconSize }) => `${$iconSize}px`};
    height: ${({ $iconSize }) => `${$iconSize}px`};
    margin-left: ${spacing.xsmall};

    path,
    circle,
    line {
      fill: ${({ $variant }) =>
        $variant === 'black' ? colors.black : colors.white};
      transition: 0.2s;
    }
  }

  img {
    transition: 0.2s;
    width: ${({ $iconSize }) => `${$iconSize}px`};
    height: ${({ $iconSize }) => `${$iconSize}px`};
    margin-left: ${spacing.xsmall};
  }

  :hover {
    color: ${({ $variant, $arrow }) =>
      !$arrow && ($variant === 'black' ? colors.gray5 : colors.gray4)};

    svg {
      * {
        fill: ${({ $variant, $arrow }) =>
          !$arrow && ($variant === 'black' ? colors.gray5 : colors.gray4)};
      }
    }
  }

  ${({ $icon, $arrow, $clean }) =>
    !$icon
    && !$arrow
    && !$clean
    && css`
      text-decoration: underline;
    `}

  ${({ $arrow }) =>
    $arrow
    && css`
      font-size: ${fontSizes.s};
      line-height: ${lineHeights.s};
      font-weight: ${fontWeights.fontMedium};
      letter-spacing: 0.1em;
      text-transform: uppercase;

      img {
        width: fit-content;
        height: fit-content;
      }

      &:hover {
        img {
          margin-left: ${spacing.small};
        }
      }
    `}
`;
