import { ParagraphStyleType, StyledParagraph } from './Paragraph.styled';
interface ParagraphProps {
  className?: string;
  children?: React.ReactNode;
  styleType?: ParagraphStyleType;
  center?: boolean;
  noMargin?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  children,
  className,
  styleType,
  center,
  noMargin,
}) => {
  return (
    <StyledParagraph
      className={className}
      $styleType={styleType}
      $center={center}
      $noMargin={noMargin}
    >
      {children}
    </StyledParagraph>
  );
};
