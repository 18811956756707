import { useCallback, useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useKey } from 'react-use';

import { routes } from '@hultafors/toeguard/helpers';
import { useGlobal } from '@hultafors/toeguard/hooks';

import { Grid } from '../Grid/Grid';
import { HTag } from '../HTag/HTag';
import { Loader } from '../Loader/Loader';
import { Products } from '../Products/Products';
import { SearchInput } from '../SearchInput/SearchInput';
import { TextButton } from '../TextButton/TextButton';

import { ProductWrapper, StyledSearch } from './AutoCompleteSearchBox.styled';

interface AutoCompleteSearchBoxProps {
  full?: boolean;
  isOpen?: boolean;
  focusInput?: boolean;
  focusCallback?(): void;
  productsCallback?(products: any): void;
  className?: string;
}

export const AutoCompleteSearchBox: React.FC<AutoCompleteSearchBoxProps> = ({
  full,
  isOpen,
}) => {
  const MIN_CHARS_BEFORE_SEARCH = 2;
  const MAX_NUMBER_OF_PRODUCTS = 3;
  const { global, parttrapApi } = useGlobal();

  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  const clearSearch = useCallback(() => {
    setSearchTerm('');
    setProducts([]);
  }, []);

  useKey('Escape', clearSearch);

  useEffect(() => {
    if (!isOpen) {
      clearSearch();
    }
  }, [isOpen, clearSearch]);

  useEffect(() => {
    setIsLoading(false);
  }, [products]);

  useEffect(() => {
    const canceled = false;
    if (searchTerm.length < MIN_CHARS_BEFORE_SEARCH) {
      setIsLoading(false);
      return;
    } else {
      setIsLoading(true);

      parttrapApi
        .searchProducts(searchTerm, false, [], 1, MAX_NUMBER_OF_PRODUCTS)
        .then((result) => {
          if (!canceled && result && !result.error) {
            setProducts(result.items);
          }
        });
    }

    // return () => (canceled = true);
  }, [searchTerm]);

  const search: React.MouseEventHandler = (event) => {
    event.preventDefault();
    if (searchTerm.length >= MIN_CHARS_BEFORE_SEARCH) {
      router.push({
        pathname: `/${routes.SEARCH}`,
        query: { q: searchTerm },
      });
    }
  };

  useKey('Escape', clearSearch);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <StyledSearch>
      <SearchInput
        onChange={onChange}
        value={searchTerm}
        placeholder={global.searchProducts ?? ''}
        search={search}
        clearSearch={clearSearch}
        name="Search"
        full={full}
        isVisible={isOpen}
      />
      {isLoading && <Loader />}
      <ProductWrapper>
        {products.length < 1
        && !isLoading
        && searchTerm.length >= MIN_CHARS_BEFORE_SEARCH && (
          <HTag type="h3" styleType="header5">
            {global.noResults}
          </HTag>
        )}
        {products.length > 0
        && !isLoading
        && searchTerm.length >= MIN_CHARS_BEFORE_SEARCH && (
          <>
            <HTag type="h2" styleType="header4">
              {global.suggestions}
            </HTag>
            <TextButton
              onClick={search}
              icon="/assets/gfx/arrow_right_16.svg"
            >
              {global.viewAll}
            </TextButton>
            <Grid
              columnGap={[{ columnGap: 'medium' }]}
              columns={[
                { columns: 1 },
                { breakpoint: 'desktopSmall', columns: 3 },
              ]}
            >
              <Products
                products={products}
                badgeText={global.new ?? ''}
                isLoading={isLoading}
                numberOfItems={MAX_NUMBER_OF_PRODUCTS}
                rrpLabel={global.rrpLabel ?? ''}
              />
            </Grid>
          </>
        )}
      </ProductWrapper>
    </StyledSearch>
  );
};
