import { ImageFragment } from '@hultafors/toeguard/types';

import { HTag } from '../HTag/HTag';
import { Image } from '../Image/Image';
import { Paragraph } from '../Paragraph/Paragraph';

import { ContentPlugStyled } from './ContentPlug.styled';

interface ContentPlugProps {
  title?: string | null;
  url?: string | null;
  image?: ImageFragment | null;
  text?: string | null;
  CTAText?: string | null;
  className?: string;
}
export const ContentPlug: React.FC<ContentPlugProps> = ({
  title,
  url,
  image,
  text,
  CTAText,
  className,
}) => {
  return (
    <ContentPlugStyled
      href={url ?? ''}
      className={className}
      data-test="contentPlug"
    >
      {image
        ? (
          <Image
            src={`${image.url}?w=800`}
            alt={image.alt || image.title || 'image'}
          />
          )
        : (
          <div className="NoImagePlaceholder" />
          )}
      <div className="TextBoxWrapper">
        <HTag
          type="h2"
          styleType={[
            { styleType: 'header3' },
            { breakpoint: 'desktop', styleType: 'header2' },
          ]}
          className="TextBoxTitle"
        >
          {title}
        </HTag>
        <Paragraph styleType="body16" className="TextBoxText">
          {text ? text : '\u00A0'}
        </Paragraph>
        <div className="TextBoxCTAWrapper">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 12L15 8L11 4"
              stroke="#111111"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 8L1 8"
              stroke="#111111"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Paragraph styleType="body16" className="TextBoxCTA">
            {CTAText}
          </Paragraph>
        </div>
      </div>
    </ContentPlugStyled>
  );
};
