import { useEffect, useState } from 'react';

import { LocalStorage } from '@hultafors/toeguard/helpers';
import { TimedDialogFragment } from '@hultafors/toeguard/types';

import { SubscriptionDialog } from '../SubscriptionDialog/SubscriptionDialog';

const TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY = 'tg_timed_dialog_renew_date';
const RENEW_INTERVAL = 6 * 30 * 24 * 3600 * 1000; // 6 MONTHS

interface TimedDialogProps {
  timedDialog: TimedDialogFragment;
}

export const TimedDialog: React.FC<TimedDialogProps> = ({ timedDialog }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    if (timedDialog?.formUrl) {
      const renewDate = LocalStorage?.get(
        TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY,
      );
      if (!renewDate || Date.now() > Number(renewDate)) {
        setTimeout(() => {
          const now = Date.now();

          setIsDialogOpen(true);
          LocalStorage?.set(
            TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY,
            now + RENEW_INTERVAL,
          );
        }, timedDialog.delay * 1000);
      }
    }
  }, [timedDialog]);

  return (
    <>
      {isDialogOpen && (
        <SubscriptionDialog
          formUrl={timedDialog.formUrl ?? ''}
          image={timedDialog.image}
          onHandleClose={closeDialog}
        />
      )}
    </>
  );
};
