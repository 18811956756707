export function generateMapUrl(...args: (string | undefined | null)[]) {
  const url = new URL('https://www.google.com/maps/search/');
  url.searchParams.set('api', '1');
  const query
    = args
      .map((argument) => argument?.trim())
      .filter(Boolean)
      .join('+') || '';
  if (query) {
    url.searchParams.set('query', query);
  }
  return url.toString();
}
