import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

export const StyledGreyInfoBox = styled.div`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.large} 0 ${spacing.medium} 0;
  }

  .content {
    background-color: ${colors.gray4};
    padding: 31px 32px;

    h2 {
      padding-bottom: ${spacing.small};
    }
  }
`;
