import { StyledH2 } from './H2.styled';

interface H2Props {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  center?: boolean;
}
export const H2: React.FC<H2Props> = ({
  className,
  style,
  children,
  center,
}) => {
  return (
    <StyledH2 className={className} style={style} $center={center}>
      {children}
    </StyledH2>
  );
};
