import { SectionStyled } from './Section.styled';
interface SectionProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}
export const Section: React.FC<SectionProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <SectionStyled className={className} style={style}>
      {children}
    </SectionStyled>
  );
};
