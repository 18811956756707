import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';

export const StyledSelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  label {
    flex-basis: 100%;
    font-size: ${fontSizes.body16};
  }
`;

export const StyledSelect = styled.select`
  appearance: none;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: ${spacing.tiny};
  border: 1px solid ${colors.gray3};
  background: url('/assets/gfx/down_16.svg');
  background-size: ${fontSizes.body16};
  background-position: calc(100% - ${spacing.small}) center;
  background-repeat: no-repeat;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.small};
  }

  &:focus {
    outline: none;
  }

  option {
    padding: ${spacing.small} ${spacing.tiny};
    line-height: 1.4em !important;

    &:disabled {
      color: ${colors.gray2};
    }
  }

  .ArrowIcon {
    width: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;
