import { ImageFragment } from '@hultafors/shared/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Paragraph } from '../Paragraph/Paragraph';

import { StyledImage } from './ImageBlock.styled';

interface ImageBlockProps {
  image?: ImageFragment | null;
  label?: string | null;
}
export const ImageBlock: React.FC<ImageBlockProps> = ({ image, label }) => {
  if (!image) {
    return null;
  }
  return (
    <StyledImage>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <img
            src={`${image.url}?w950`}
            alt={image.alt || image.title || 'image'}
            title={image.title ?? ''}
          />
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 5, start: 3 },
            { breakpoint: 'desktop', columns: 4, start: 4 },
          ]}
        >
          {label && <Paragraph styleType="body14">{label}</Paragraph>}
        </GridChild>
      </Grid>
    </StyledImage>
  );
};
