import Markdown from 'markdown-to-jsx';

import { useGlobal } from '@hultafors/toeguard/hooks';
import { IconLinkFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { RouterLink } from '../RouterLink/RouterLink';
import { Section } from '../Section/Section';
import { SubscribeArea } from '../SubscribeArea/SubscribeArea';

import {
  BottomSection,
  Content,
  CookieSettings,
  FooterStyled,
  MiddleSection,
  SubscribeAreaWrapper,
} from './Footer.styled';

export const Footer = () => {
  const { global, footer } = useGlobal();
  const handleOnPressSettings = () => {
    // TODO typescript, tried index.d.ts and typeRoots tsconfig.ts & .json
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };
  if (!footer || !global) {
    return null;
  }

  const socialMediaMapper = (item: IconLinkFragment, index: number) => {
    if (!item.url) {
      return null;
    }
    return (
      <a
        key={`SocialMedia-${index}`}
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        title={item.icon?.title ?? ''}
      >
        <img
          src={item.icon?.url ?? ''}
          title={item.icon?.title ?? ''}
          alt={item.icon?.alt ?? ''}
        />
      </a>
    );
  };

  return (
    <FooterStyled>
      <Section>
        <Grid className="Grid">
          <GridChild>
            <div className="LogoWrapper">
              <img
                src="/assets/gfx/logo.svg"
                alt="Toe Guard logotype"
                title="Toe Guard"
              />
            </div>

            <MiddleSection>
              <div className="FooterFunctions">
                {global?.urlToPartnerPortal && (
                  <a
                    href={global.urlToPartnerPortal}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="PartnerLoginLink"
                  >
                    {footer.partnerLogin}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8H9M9 8L6 5M9 8L6 11"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 1H14C14.5523 1 15 1.44772 15 2V14C15 14.5523 14.5523 15 14 15H11"
                        stroke="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                )}
              </div>

              <Content>
                <div className="IntroText">
                  {footer.hultaforsBrands.length > 0 && (
                    <Markdown
                      options={{ forceBlock: true }}
                      className="markdown-wrapper"
                    >
                      {footer.introText ?? ''}
                    </Markdown>
                  )}
                  {footer.hultaforsBrands.map((item, index) => {
                    return (
                      <span key={`Company-${index}`}>
                        <RouterLink to={item.url} name={item.title}>
                          {item.title}
                        </RouterLink>
                        {index < footer.hultaforsBrands.length - 1 && (
                          <>{' | '}</>
                        )}
                      </span>
                    );
                  })}
                </div>
                <Markdown
                  options={{ forceBlock: true }}
                  className="markdown-wrapper"
                >
                  {footer.moreInfoText ?? ''}
                </Markdown>
              </Content>

              {footer.userEmailFormUrl && (
                <SubscribeAreaWrapper>
                  <SubscribeArea
                    subscribeInfoText={footer.subscribeInfoText}
                    subscribeButtonText={footer.subscribeButtonText}
                    userEmailFormUrl={footer.userEmailFormUrl}
                    userEmailFormImage={footer.userEmailFormImage}
                  />
                </SubscribeAreaWrapper>
              )}
            </MiddleSection>
            {footer.menu && (
              <BottomSection>
                <div className="BottomSectionLinks">
                  {footer.menu.map((item, index) => {
                    return (
                      <RouterLink
                        to={item.url}
                        name={item.title}
                        key={`Company-${index}`}
                      >
                        {item.title}
                        {' '}
                        {index < footer.menu.length - 1 && (
                          <>{'\u00A0|\u00A0'}</>
                        )}
                      </RouterLink>
                    );
                  })}
                  {'\u00A0|\u00A0'}
                  <CookieSettings onClick={handleOnPressSettings}>
                    {footer.cookieSettings}
                  </CookieSettings>
                </div>
                <div className="SocialMedia">
                  {footer.socialMediaMenu.map(socialMediaMapper)}
                </div>
              </BottomSection>
            )}
          </GridChild>
        </Grid>
      </Section>
    </FooterStyled>
  );
};
