import {
  BlueInfoBoxFragment,
  BulletListFragment,
  DotDigitalFormFragment,
  GreyInfoBoxFragment,
  ImageBlockFragment,
  ImageSliderFragment,
  QuoteFragment,
  TextBlockFragment,
  TwoImageBlockFragment,
  VideoBlockFragment,
} from './dato/graphql-types';

export type DynamicContentItem =
  | BlueInfoBoxFragment
  | BulletListFragment
  | DotDigitalFormFragment
  | GreyInfoBoxFragment
  | ImageBlockFragment
  | ImageSliderFragment
  | QuoteFragment
  | TextBlockFragment
  | TwoImageBlockFragment
  | VideoBlockFragment;

export function isBlueInfoBox(
  item: DynamicContentItem,
): item is BlueInfoBoxFragment {
  return item.__typename === 'BlueInfoBoxRecord';
}
export function isBulletList(
  item: DynamicContentItem,
): item is BulletListFragment {
  return item.__typename === 'BulletListRecord';
}
export function isDotDigitalForm(
  item: DynamicContentItem,
): item is DotDigitalFormFragment {
  return item.__typename === 'DotDigitalFormRecord';
}
export function isGreyInfoBox(
  item: DynamicContentItem,
): item is GreyInfoBoxFragment {
  return item.__typename === 'GreyInfoBoxRecord';
}
export function isImageBlock(
  item: DynamicContentItem,
): item is ImageBlockFragment {
  return item.__typename === 'ImageRecord';
}
export function isImageSlider(
  item: DynamicContentItem,
): item is ImageSliderFragment {
  return item.__typename === 'ImageSliderRecord';
}
export function isQuote(item: DynamicContentItem): item is QuoteFragment {
  return item.__typename === 'QuoteRecord';
}
export function isTextBlock(
  item: DynamicContentItem,
): item is TextBlockFragment {
  return item.__typename === 'TextBlockRecord';
}
export function isTwoImageBlock(
  item: DynamicContentItem,
): item is TwoImageBlockFragment {
  return item.__typename === 'TwoImageBlockRecord';
}
export function isVideoBlock(
  item: DynamicContentItem,
): item is VideoBlockFragment {
  return item.__typename === 'VideoRecord';
}
