import { useMemo } from 'react';

import { ContactPageFragment, OfficeFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { OfficeList } from '../OfficeList/OfficeList';

import { ContactStoresStyled } from './ContactStores.styled';

interface ContactStoresProps
  extends Pick<
    ContactPageFragment,
    | 'salesOfficesHeader'
    | 'exportMarketsHeader'
    | 'visitLocalWeb'
    | 'viewOnMapLabel'
  > {
  allOffices?: OfficeFragment[];
}
export const ContactStores: React.FC<ContactStoresProps> = ({
  allOffices,
  visitLocalWeb,
  viewOnMapLabel,
  salesOfficesHeader,
  exportMarketsHeader,
}) => {
  const exportOffices = useMemo(
    () =>
      allOffices?.filter(
        ({ typeOfOffice }) => typeOfOffice === 'Export markets',
      ),
    [allOffices],
  );

  const salesOffices = useMemo(
    () =>
      allOffices?.filter(({ typeOfOffice }) => typeOfOffice === 'Sales office'),
    [allOffices],
  );

  return (
    <ContactStoresStyled>
      <Grid columnGap={0}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <OfficeList
            title={salesOfficesHeader}
            offices={salesOffices}
            visitLocalWeb={visitLocalWeb}
            viewOnMapLabel={viewOnMapLabel}
          />
          <OfficeList
            title={exportMarketsHeader}
            offices={exportOffices}
            visitLocalWeb={visitLocalWeb}
            viewOnMapLabel={viewOnMapLabel}
          />
        </GridChild>
      </Grid>
    </ContactStoresStyled>
  );
};
