import styled from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';

export const StyledIconButtonWrapper = styled.button`
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  min-height: ${spacing.regular};
  min-width: ${spacing.regular};
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
`;
