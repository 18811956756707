import { ImageFragment } from '@hultafors/toeguard/types';

import { Image } from '../Image/Image';

import {
  BackDrop,
  DialogContent,
  ImageWrapper,
  StyledDialog,
  StyledIFrameContainer,
} from './SubscriptionDialog.styled';
interface SubscriptionDialogProps {
  formUrl?: string | null;
  image?: ImageFragment | null;
  onHandleClose?: (...args: any[]) => any;
}
export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  formUrl,
  image,
  onHandleClose,
}) => {
  const { url: imageUrl, title: imageTitle } = image || {};
  return (
    <>
      <BackDrop />
      <StyledDialog
        isFullscreen={false}
        onClose={onHandleClose}
        closeButtonLabel={
          <Image src="/assets/gfx/close_24.svg" alt="Close Icon" />
        }
      >
        <DialogContent>
          {imageUrl && (
            <ImageWrapper>
              <Image src={`${imageUrl}?w=800`} alt={imageTitle ?? ''} />
            </ImageWrapper>
          )}
          <StyledIFrameContainer title="dialog-form" src={formUrl ?? ''} />
        </DialogContent>
      </StyledDialog>
    </>
  );
};
