import { PagePlugFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Image } from '../Image/Image';
import { RouterLink } from '../RouterLink/RouterLink';

import { PagePlugsStyled } from './PagePlugs.styled';
interface PagePlugsProps {
  plugs?: PagePlugFragment[];
}
export const PagePlugs: React.FC<PagePlugsProps> = ({ plugs }) => {
  if (!plugs?.length) {
    return null;
  }
  function plugMapper(item: PagePlugFragment) {
    return (
      <GridChild
        key={`PagePlug-${item.id}`}
        className="GridChild"
        columnSpan={[
          { columns: 4 },
          { breakpoint: 'mobileMax', columns: 6 },
          { breakpoint: 'desktop', columns: 4 },
        ]}
      >
        <RouterLink to={item.url}>
          <Image src={`${item.image?.url}?w=900`} alt={item.image?.alt ?? ''} />
          <HTag className="Text" type="h4" styleType="header3">
            {item.title}
          </HTag>
        </RouterLink>
      </GridChild>
    );
  }
  return (
    <PagePlugsStyled>
      <Grid columnGap={48} rowGap={48}>
        {plugs.map(plugMapper)}
      </Grid>
    </PagePlugsStyled>
  );
};
