import styled from 'styled-components';

import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';

export const StyledH1 = styled.h1<{ $center?: boolean }>`
  font-size: ${fontSizes.h1};
  line-height: ${lineHeights.xs};
  font-weight: ${fontWeights.fontMedium};
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
`;
