import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

export const ColorPlugsStyled = styled.div`
  padding: 0 ${spacing.small};
  padding-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0 ${spacing.medium};
    padding-bottom: ${spacing.medium};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 0 ${spacing.regular};
    padding-bottom: ${spacing.large};
  }

  .Grid {
    grid-auto-rows: 1fr;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      grid-auto-rows: auto;
    }
  }

  .Box {
    padding-block: ${spacing.large} ${spacing.regular};
    padding-inline: ${spacing.regular};
    display: flex;
    align-items: flex-end;
    min-height: 296px;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      min-height: 312px;
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      min-height: 448px;
    }

    .ContentWrapper a {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &.Box-1 {
      background-color: ${colors.black};

      h3 {
        color: ${colors.white};
      }
    }

    &.Box-2 {
      background-color: ${colors.gray4};
    }

    &.Box-3 {
      background-color: ${colors.black};

      h3 {
        color: ${colors.white};
      }

      @media screen and (min-width: ${breakpoints.desktop}) {
        padding: ${spacing.medium};
        align-items: center;
        min-height: 328px;
      }
    }

    .CTA {
      padding-top: ${spacing.regular};

      p {
        font-size: ${fontSizes.header4};
        line-height: ${lineHeights.header4};
        font-weight: ${fontWeights.fontMedium};
        font-family: ${fontFamilies.fontRegular};
      }
    }
  }
`;
