import { useId } from 'react';

import { fixSlash } from '@hultafors/toeguard/helpers';
import { InfoPagePlugFragment } from '@hultafors/toeguard/types';

import { ContentPlug } from '../ContentPlug/ContentPlug';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { StyledExploreMorePlugs } from './ExploreMorePlugs.styled';

interface ExploreMorePlugsProps {
  plugs?: InfoPagePlugFragment[] | null;
  CTAText?: string | null;
}
export const ExploreMorePlugs: React.FC<ExploreMorePlugsProps> = ({
  plugs,
  CTAText,
}) => {
  const uid = useId();
  if (!plugs?.length) {
    return null;
  }
  const getGridStartPosition = (i: number) => {
    return i % 2 === 0 ? 1 : 7;
  };
  const getGridStartPositionDesktopLarge = (i: number) => {
    return i % 2 === 0 ? 1 : 8;
  };
  const plugMapper = (plug: InfoPagePlugFragment, index: number) => {
    return (
      <GridChild
        key={`ExploreMorePlugs-${uid}-${plug.id}`}
        className="GridChild"
        columnSpan={[
          { columns: 4 },
          {
            breakpoint: 'mobileMax',
            columns: 6,
            start: getGridStartPosition(index),
          },
          {
            breakpoint: 'desktopLarge',
            columns: 5,
            start: getGridStartPositionDesktopLarge(index),
          },
        ]}
      >
        <ContentPlug
          title={plug.title}
          text={plug.description}
          CTAText={plug.ctaText ? plug.ctaText : CTAText}
          url={fixSlash(plug.url ?? '')}
          image={plug.image}
        />
      </GridChild>
    );
  };
  return (
    <StyledExploreMorePlugs>
      <Grid
        columnGap={[{ breakpoint: 'mobileMax', columnGap: 'medium' }]}
        rowGap={[{ rowGap: 48 }, { breakpoint: 'mobileMax', rowGap: 96 }]}
      >
        {plugs.map(plugMapper)}
      </Grid>
    </StyledExploreMorePlugs>
  );
};
