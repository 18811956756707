import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import { StyledFeatures } from './Features.styled';

interface FeaturesProps {
  title?: string | null;
  features?: any[];
}

export const Features: React.FC<FeaturesProps> = ({ title, features }) => {
  if (!features || (Array.isArray(features) && features.length < 1)) {
    return null;
  }
  return (
    <StyledFeatures data-test="features">
      <HTag type="h2" styleType="header4">
        {title}
      </HTag>
      {features?.map((feat, i) => (
        <div className="feature" key={`feature${i}`}>
          <img
            src={feat.icon?.url}
            alt={feat.icon?.alt || ''}
            title="Feature"
          />
          <Paragraph styleType="body16">{feat.name}</Paragraph>
        </div>
      ))}
    </StyledFeatures>
  );
};
