import { useId } from 'react';

import dynamic from 'next/dynamic';

import { generateMapUrl } from '@hultafors/shared/helpers';

import { OfficeFragment } from '@hultafors/toeguard/types';

import { StoreItem } from '../StoreItem/StoreItem';

import { Title } from './OfficeList.styled';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Accordion),
);

interface OfficeListProps {
  title?: string | null;
  offices?: OfficeFragment[] | null;
  visitLocalWeb?: string | null;
  viewOnMapLabel?: string | null;
}

export const OfficeList: React.FC<OfficeListProps> = ({
  title,
  offices = [],
  visitLocalWeb,
  viewOnMapLabel,
}) => {
  const uid = useId();

  function officeMapper(office: OfficeFragment) {
    const key = `OfficeList-${uid}-${office.id}`;
    return (
      <StoreItem
        label={office.name}
        key={key}
        address={office.address}
        zipCode={office.zipCode}
        city={office.city}
        country={office.country}
        mapLink={generateMapUrl(
          office.name,
          office.country,
          office.address,
          office.zipCode,
        )}
        view_on_map={viewOnMapLabel}
        emailAddress={office.email}
        phone={office.phoneNumber}
        website={office.websiteUrl}
        visit_web_site={visitLocalWeb}
      />
    );
  }
  return (
    <div>
      {title && <Title>{title}</Title>}
      {!!offices?.length && (
        <Accordion allowZeroExpanded>{offices.map(officeMapper)}</Accordion>
      )}
    </div>
  );
};
