import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const ContactStoresStyled = styled.div`
  padding: 0 ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;
