import { StyledH3 } from './H3.styled';

interface H3Props {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  center?: boolean;
}
export const H3: React.FC<H3Props> = ({
  className,
  style,
  children,
  center,
}) => {
  return (
    <StyledH3 className={className} style={style} $center={center}>
      {children}
    </StyledH3>
  );
};
