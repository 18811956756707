import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useGlobal } from '@hultafors/toeguard/hooks';

import { HeadComponent } from '../HeadComponent/HeadComponent';

import { StyledContentWrapper } from './Page.styled';
const ErrorBoundary = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.ErrorBoundary),
);
interface PageProps {
  location?: {
    pathname?: string;
  };
  metadata?: {
    description?: string;
    image?: {
      alt?: string;
      url?: string;
      width?: number;
      height?: number;
    };
    lang?: string;
    title?: string;
    type?: string;
  };
  market: string;
  children: React.ReactNode;
}

export const Page: React.FC<PageProps> = ({
  metadata,
  market = 'com',
  children,
}) => {
  const { pathname } = useRouter();
  const { settings } = useGlobal();
  const canonicalBase = `https://${settings.hostname}`;
  return !pathname
    ? null
    : (
      <ErrorBoundary>
        <HeadComponent
          url={pathname}
          canonicalBase={canonicalBase}
          {...metadata}
          prerenderStatusCode={200}
        />
        <StyledContentWrapper>{children}</StyledContentWrapper>
      </ErrorBoundary>
      );
};
