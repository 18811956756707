import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import { StyledGreyInfoBox } from './GreyInfoBox.styled';
interface GreyInfoBoxProps {
  title?: string | null;
  body?: string | null;
}
export const GreyInfoBox: React.FC<GreyInfoBoxProps> = ({ title, body }) => {
  if (!title && !body) {
    return null;
  }
  return (
    <StyledGreyInfoBox>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <div className="content">
            {title && (
              <HTag type="h2" styleType="header4">
                {title}
              </HTag>
            )}
            {body && <Paragraph styleType="body16">{body}</Paragraph>}
          </div>
        </GridChild>
      </Grid>
    </StyledGreyInfoBox>
  );
};
