import { breakpoints } from '@hultafors/shared/constants';

import { constructBackLink } from '@hultafors/toeguard/helpers';

import { ArrowLink } from '../ArrowLink/ArrowLink';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { BackLinkStyled } from './BackLink.styled';

interface BackLinkProps {
  slug?: string;
  parentSlug?: string;
  route?: string;
  className?: string;
  fullWidth?: boolean;
  goBackSearch?: string;
}
export const BackLink: React.FC<BackLinkProps> = ({
  route = '',
  slug,
  className,
  fullWidth,
  goBackSearch,
}) => {
  if (!slug) {
    return null;
  }
  const link = constructBackLink(route, slug);
  return (
    <BackLinkStyled className={className}>
      <Grid maxWidth={fullWidth ? '3000px' : breakpoints.maxPageWidth}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 5, start: 1 },
          ]}
        >
          <ArrowLink
            secondArrow
            title={link.title}
            url={link.url}
            search={goBackSearch}
            smallText
          />
        </GridChild>
      </Grid>
    </BackLinkStyled>
  );
};
