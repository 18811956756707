import { Features } from '../Features/Features';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

import { CategoryHeaderStyled } from './CategoryHero.styled';
interface CategoryHeroProps {
  title: string;
  description?: string;
  featuresHeader?: string;
  features?: any[];
}
export const CategoryHero: React.FC<CategoryHeroProps> = ({
  title,
  description,
  featuresHeader,
  features,
}) => {
  return (
    <CategoryHeaderStyled>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12, start: 1 },
            { breakpoint: 'desktop', columns: 5, start: 1 },
          ]}
        >
          <div>
            {title && (
              <HTag type="h1" styleType="header2">
                {title}
              </HTag>
            )}
          </div>
        </GridChild>
      </Grid>
      {description && (
        <Grid className="TextGrid">
          <GridChild
            className="Description"
            columnSpan={[
              { columns: 4 },
              { breakpoint: 'mobileMax', columns: 12, start: 1 },
              { breakpoint: 'desktop', columns: 5, start: 1 },
            ]}
          >
            <HTag type="h2" styleType="header5">
              {description}
            </HTag>
          </GridChild>
          {description && (
            <GridChild
              columnSpan={[
                { columns: 4 },
                { breakpoint: 'mobileMax', columns: 12, start: 1 },
                { breakpoint: 'desktop', columns: 4, start: 8 },
              ]}
            >
              <Features title={featuresHeader} features={features} />
            </GridChild>
          )}
        </Grid>
      )}
    </CategoryHeaderStyled>
  );
};
