import styled from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';

import { HTag } from '../HTag/HTag';

export const Title = styled(HTag).attrs({
  type: 'h3',
  styleType: 'header4',
})`
  margin-block-end: ${spacing.regular};
`;
