import styled from 'styled-components';

import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

export const StyledSearch = styled.div`
  width: 100%;
  margin: 0 auto;

  input {
    width: 100%;
    border: 0;
    font-weight: ${fontWeights.fontRegular};
    line-height: ${lineHeights.header4};
    font-size: ${fontSizes.s};
    box-sizing: border-box;
    appearance: none;

    &:focus {
      outline: 0;
    }
  }
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${spacing.regular};
  align-items: center;
`;
