import { useEffect, useRef, useState } from 'react';

import Slider from 'react-slick';

import { colors } from '@hultafors/toeguard/helpers';

import { Image } from '../Image/Image';
import { Paragraph } from '../Paragraph/Paragraph';
import { ProductImageDialog } from '../ProductImageDialog/ProductImageDialog';
import { TextButton } from '../TextButton/TextButton';

import { ProductSliderStyled } from './ProductSlider.styled';

interface ProductSliderProps {
  images?: any[];
  productId?: string;
  productName?: string;
}
export const ProductSlider: React.FC<ProductSliderProps> = ({
  images,
  productId,
  productName,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const slider = useRef<any>(null);

  useEffect(() => {
    setCurrentIndex(0);
    slider.current?.slickGoTo(0);
  }, [images]);

  const changedIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const SETTINGS = {
    arrows: true,
    dots: false,
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    nextArrow: <Arrow className="Next" label="next" />,
    prevArrow: <Arrow className="Prev" label="previous" />,
    responsive: [
      {
        breakpoint: 860,
        settings: {
          arrows: false,
        },
      },
    ],
    afterChange: changedIndex,
  };
  const canRenderImage = () => {
    return Array.isArray(images) && images.length > 0;
  };
  const formatColor = (color: string) => {
    return color.substring(1, color.length);
  };
  const goToSlider = (i: number) => {
    changedIndex(i);
    slider.current.slickGoTo(i);
  };
  const renderCustomPaging = () => {
    return (
      <div className="ThumbNailWrapper">
        {images?.length
        && images.length > 1
        && images.map((image: any, index) => (
          <span
            key={`productItem${index}`}
            className={currentIndex === index ? 'Active' : ''}
          >
            <Image
              src={`${image.url}?w=900&bgcolor=${formatColor(
                  colors.gray6,
                )}&format=jpg`}
              onClick={() => goToSlider(index)}
              alt={image.caption || image.description || 'product image'}
            />
          </span>
        ))}
      </div>
    );
  };
  const renderZoomIcon = () => {
    return (
      <TextButton onClick={showDialog}>
        <svg
          className="ZoomIcon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99994 1L7.99994 15"
            stroke="#111111"
            strokeLinecap="round"
          />
          <path d="M15 8L1 8" stroke="#111111" strokeLinecap="round" />
        </svg>
      </TextButton>
    );
  };
  const showDialog = () => {
    document.body.style.overflowY = 'hidden';
    setIsDialogOpen(true);
  };
  const closeDialog = () => {
    document.body.style.overflowY = 'initial';
    setIsDialogOpen(false);
  };
  const renderSlider = () => {
    return (
      <Slider key={productId} {...SETTINGS} className="article" ref={slider}>
        {images?.map((image, index) => (
          <div key={`SliderImage${index}`} className="ImgWrapper">
            <img
              src={`${image.url}?w=900&bgcolor=${formatColor(
                colors.gray6,
              )}&format=jpg`}
              alt={image.caption || `${productName} product image`}
              title={image.caption}
            />
          </div>
        ))}
      </Slider>
    );
  };
  const renderCounter = () => {
    return (
      <div className="Counter">
        <Paragraph styleType="body12">
          {currentIndex ? currentIndex + 1 : 1}
          {' '}
          /
          {images?.length ?? 1}
        </Paragraph>
      </div>
    );
  };
  return (
    <ProductSliderStyled>
      {isDialogOpen && (
        <ProductImageDialog images={images} onClick={closeDialog} />
      )}
      {canRenderImage()
        ? (
          <div className="SliderAndContentWrapper">
            {renderCustomPaging()}
            <div className="SliderAndCounterWrapper">
              {renderZoomIcon()}
              {renderSlider()}
              {images?.length && images.length > 1 && renderCounter()}
            </div>
          </div>
          )
        : (
          <Image
            src="/assets/gfx/noimage.png?width=500"
            alt={images?.[0].caption || `${productName} product image`}
          />
          )}
    </ProductSliderStyled>
  );
};
interface ArrowProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
}

const Arrow: React.FC<ArrowProps> = ({ className, onClick, label }) => {
  return <button className={className} aria-label={label} onClick={onClick} />;
};
