import { useState } from 'react';

import { useKey } from 'react-use';

import { colors } from '@hultafors/toeguard/helpers';

import { Dialog } from '../Dialog/Dialog';
import { Image } from '../Image/Image';
import { Loader } from '../Loader/Loader';

import { ProductImageDialogStyled } from './ProductImageDialog.styled';
interface ProductImageDialogProps {
  images?: any[];
  onClick?: (...args: any[]) => any;
}
export const ProductImageDialog: React.FC<ProductImageDialogProps> = ({
  images,
  onClick,
}) => {
  const [loading, setLoading] = useState(true);
  useKey('Escape', onClick);

  const formatColor = (color: string) => {
    return color.substring(1, color.length);
  };
  const firstImageLoaded = () => {
    setLoading(false);
  };
  return (
    <ProductImageDialogStyled>
      <Dialog
        onClose={onClick}
        closeButtonLabel={
          <Image src="/assets/gfx/close_24.svg" alt="Close Icon" />
        }
      >
        {loading && <Loader fullScreen />}
        {images?.map((image, index) => (
          <div
            key={`image${index}`}
            className={(loading ? 'Hide ' : 'Show ') + 'DialogImage'}
          >
            <img
              src={`${image.url}?w=1900&bgcolor=${formatColor(
                colors.gray6,
              )}&format=jpg`}
              alt={image.caption}
              title={image.caption}
              onLoad={index === 0 ? firstImageLoaded : () => null}
            />
          </div>
        ))}
      </Dialog>
    </ProductImageDialogStyled>
  );
};
