import { TextButton } from '../TextButton/TextButton';

import { DialogContent, DialogHeader, DialogWrapper } from './Dialog.styled';
interface DialogProps {
  closeButtonLabel?: React.ReactNode | string;
  onClose?: (...args: any[]) => any;
  isFullscreen?: boolean;
  height?: string;
  width?: string;
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  children?: React.ReactNode;
}
export const Dialog: React.FC<DialogProps> = ({
  children,
  closeButtonLabel = 'Close',
  onClose,
  isFullscreen = true,
  height = '100%',
  width = '100%',
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
}) => {
  return (
    <DialogWrapper
      $top={top}
      $right={right}
      $bottom={bottom}
      $left={left}
      $height={height}
      $width={width}
    >
      <DialogHeader $isFullscreen={isFullscreen}>
        <TextButton data-test="DialogClose" onClick={onClose}>
          {closeButtonLabel}
        </TextButton>
      </DialogHeader>
      <DialogContent>{children}</DialogContent>
    </DialogWrapper>
  );
};
