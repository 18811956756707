import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';

export const StyledProductDetailData = styled.div`
  padding-block: ${spacing.regular};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: 56px 0 ${spacing.medium} 0;
    margin-top: 0;
  }

  .InfoFirstBlock {
    position: relative;

    .NewLabel {
      color: ${colors.gray1};
      margin-bottom: ${spacing.xsmall};

      @media screen and (min-width: ${breakpoints.desktopSmall}) {
        position: absolute;
        top: -${spacing.regular};
      }
    }
  }

  .Favorite {
    position: absolute;
    top: 0;
    right: 0;
  }

  .ArticleNumber {
    margin: 0;
    margin-top: ${spacing.xsmall};
    color: ${colors.gray1};
  }

  .PriceWrapper {
    display: flex;
    align-items: baseline;
    padding-top: ${spacing.small};

    p {
      margin-bottom: 0;
    }
  }

  .RRPLabel {
    color: ${colors.gray1};
    text-transform: uppercase;
    margin-left: ${spacing.xsmall};
  }
`;
