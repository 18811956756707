import styled from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';

export const CategoryHeaderStyled = styled.section`
  .TextGrid {
    padding-top: ${spacing.regular};
  }

  .Description {
    padding-bottom: ${spacing.medium};
  }
`;
