import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

export const StyledBlueInfoBox = styled.div`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.medium} 0;
  }

  .content {
    background-color: ${colors.hellBlue};
    color: ${colors.white};
    padding: 29px 24px;

    h2 {
      color: ${colors.white};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding: 37px 32px;
    }
  }
`;
