import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

const arrowWidth = 16;

export const ProductSliderStyled = styled.div`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: ${spacing.small} 0 0 0;
    margin-bottom: ${spacing.medium};
  }

  *:focus {
    outline: none;
  }

  .SliderAndContentWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      width: calc(100% - 69px);
    }
  }

  .SliderAndCounterWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    button {
      display: none;
    }

    .ZoomIcon path {
      fill: ${colors.gray1};
      stroke: ${colors.gray1};
    }

    button:hover {
      svg {
        path {
          stroke: ${colors.blackHover};
        }
      }
    }

    .ZoomIcon {
      position: absolute;
      top: ${spacing.xsmall};
      right: ${spacing.xsmall};
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      padding-left: ${spacing.medium};

      button {
        display: block;
        position: absolute;
        top: 0;
        z-index: 1;
        right: 0;
      }
    }
  }

  .ThumbNailWrapper img {
    min-width: 69px;
    max-width: 69px;
    margin-bottom: ${spacing.xsmall};
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .ThumbNailWrapper {
    display: none;
    min-width: 69px;
    max-width: 69px;

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      display: block;
    }

    .Active {
      img {
        border: 1px solid ${colors.gray2};
      }
    }

    img:hover {
      cursor: pointer;
    }
  }

  .ImgWrapper {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
  }

  .Counter {
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
    align-items: center;
    justify-content: flex-end;
    height: auto;
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    p {
      margin-right: ${spacing.small};
      margin-bottom: ${spacing.xsmall};
    }
  }

  .slick-slider {
    position: relative;
    display: block;
    height: 100%;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-prev,
  .slick-next {
    border: none;
    outline: 0;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }
  }

  .slick-slider.article {
    .slick-prev,
    .slick-next {
      width: ${spacing.small};
      height: ${spacing.small};
      position: absolute;
      z-index: 1;
      bottom: -${spacing.regular};
      background: url('/assets/gfx/product_slider_arrow.svg') center center
        no-repeat;
      background-position: center;
      top: calc(50% - ${arrowWidth / 2}px);
    }

    .slick-prev {
      background-size: ${arrowWidth}px;
      left: 0;
      transform: rotate(180deg);
      margin-left: ${spacing.small};
    }

    .slick-next {
      background-size: ${arrowWidth}px;
      right: 0;
      margin-right: ${spacing.small};
    }

    .ImgWrapper {
      background-color: #fff;

      img {
        width: 100%;
        max-width: 100%;
        max-height: 800px;
        object-fit: cover;
      }
    }
  }

  .slick-list {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: 100%;
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    display: table;
    content: '';
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;
