import { useEffect, useId, useRef } from 'react';

import { FieldWrapper, FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { Label } from '../Label/Label';

import { StyledInput } from './Input.styled';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  inline?: boolean;
  label?: string;
  wrapperProps?: FieldWrapperProps;
  isVisible?: boolean;
};

export const Input: React.FC<InputProps> = ({
  label,
  id,
  inline,
  wrapperProps,
  isVisible,
  type = 'text',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const uid = useId();
  const inputId = `Input-${id}-${uid}`;

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
    }
  }, [isVisible]);

  return (
    <FieldWrapper inline={inline} {...wrapperProps}>
      {label && <Label htmlFor={inputId}>{label}</Label>}
      <StyledInput id={inputId} ref={inputRef} {...rest} />
    </FieldWrapper>
  );
};
