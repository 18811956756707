import DownloadSvg from '../../svg/download.svg';
import FileSvg from '../../svg/file.svg';
import { Paragraph } from '../Paragraph/Paragraph';

import { DownloadFileBoxStyled } from './DownloadFileBox.styled';
interface DownloadFileBoxProps {
  url?: string;
  size?: string | number;
  label?: string;
  filetype?: string;
  className?: string;
}
export const DownloadFileBox: React.FC<DownloadFileBoxProps> = ({
  url,
  size,
  label,
  filetype,
  className,
}) => {
  // Add zero-width space to allow line break after underscore
  const soft = label?.replace(/_/g, '_\u200b') ?? '';
  return (
    <DownloadFileBoxStyled data-test="DownloadFileBox" className={className}>
      <a href={url}>
        <FileSvg
          className="Icon"
          width={16}
          height={16}
          aria-hidden={true}
          focusable={false}
        />
        <div className="TextWrapper">
          <p className="Label">{soft}</p>
          <Paragraph styleType="body10" className="Size">
            {filetype}
            {' '}
            {size}
          </Paragraph>
        </div>
        <DownloadSvg
          className="Icon"
          width={16}
          height={16}
          aria-hidden={true}
          focusable={false}
        />
      </a>
    </DownloadFileBoxStyled>
  );
};
