import { Paragraph } from '../Paragraph/Paragraph';
import { RouterLink } from '../RouterLink/RouterLink';

import { ArrowLinkStyled } from './ArrowLink.styled';
interface ArrowLinkProps {
  title?: string | null;
  url?: string | null;
  color?: 'black' | 'blue' | 'white';
  large?: boolean;
  smallText?: boolean;
  secondArrow?: boolean;
  rotateIcon?: boolean;
  search?: string | null;
  className?: string;
  style?: React.CSSProperties;
}
export const ArrowLink: React.FC<ArrowLinkProps> = ({
  className,
  style,
  rotateIcon,
  smallText,
  color = 'black',
  ...props
}) => {
  const renderSmallArrow = () =>
    props.secondArrow
      ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 7L7 3L11 7"
            stroke="#111111"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.00001 3L7.00001 11L13 11"
            stroke="#111111"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        )
      : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 12L15 8L11 4"
            stroke="#111111"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15 8L1 8"
            stroke="#111111"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        );
  const renderBigArrow = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22L22 12L12 2"
        stroke="#111111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 12L2 12"
        stroke="#111111"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const renderText = () => {
    if (props.url) {
      return (
        <RouterLink to={props.url} search={props.search ?? ''}>
          {props.title}
        </RouterLink>
      );
    } else {
      return <Paragraph styleType="body14">{props.title}</Paragraph>;
    }
  };
  return (
    <ArrowLinkStyled
      className={className}
      style={style}
      $rotateIcon={rotateIcon}
      $smallText={smallText}
      $color={color}
    >
      <div className={`Wrapper ${color}`}>
        {props.large ? renderBigArrow() : renderSmallArrow()}
        {renderText()}
      </div>
    </ArrowLinkStyled>
  );
};
