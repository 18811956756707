import React, { useState } from 'react';

import { IconButtonWrapper } from '../IconButtonWrapper/IconButtonWrapper';
import { Input } from '../Input/Input';
import { Paragraph } from '../Paragraph/Paragraph';

import { SearchInputStyled } from './SearchInput.styled';

interface SearchInputProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  search: React.FormEventHandler;
  clearSearch?: (...args: any[]) => any;
  value: string;
  placeholder?: string;
  name?: string;
  iconUrl?: string;
  focusCallBack?: (...args: any[]) => any;
  className?: string;
  displaySearchMessage?: string;
  searchValueForDisplay?: string;
  isSearchView?: boolean;
  isVisible?: boolean;
  full?: boolean;
  children?: React.ReactNode;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  isVisible,
  name = 'SearchBox',
  clearSearch,
  onChange,
  search,
  full,
  ...props
}) => {
  const [animate, setAnimate] = useState(false);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange?.(event);
    setAnimate(!!event.target.value);
  };
  const handleClearSearch: React.MouseEventHandler<HTMLButtonElement> = (
    event,
  ) => {
    event.preventDefault();
    setAnimate(false);
    clearSearch?.();
  };
  const handleSubmit: React.FormEventHandler = (event) => {
    search(event);
  };
  return (
    <SearchInputStyled className={props.className} $full={full}>
      <form name="search-form" onSubmit={handleSubmit} autoComplete="off">
        <IconButtonWrapper
          name="Search"
          className="IconButton search"
          type="button"
          onClick={handleSubmit}
          aria-label="search"
        >
          <img
            src="/assets/gfx/search.svg"
            alt="Search"
            title="Search"
            width={24}
            height={24}
          />
        </IconButtonWrapper>
        <Input
          value={props.value}
          id={name}
          aria-label="Search"
          name={name}
          placeholder={props.placeholder}
          onChange={handleChange}
          isVisible={isVisible}
        />

        <IconButtonWrapper
          name="close menu"
          className={(animate ? 'animate ' : '') + 'IconButton close'.trim()}
          type="button"
          onClick={handleClearSearch}
        >
          <img
            src="/assets/gfx/close_circle_16.svg"
            alt="close icon"
            title="Close"
          />
        </IconButtonWrapper>
      </form>
      {props.children}
      {props.displaySearchMessage && (
        <Paragraph styleType="body16" className="ResultText">
          {props.displaySearchMessage}
        </Paragraph>
      )}
    </SearchInputStyled>
  );
};
