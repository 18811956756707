import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import { StyledQuote } from './Quote.styled';

interface QuoteProps {
  body?: string | null;
  author?: string | null;
  authorProfession?: string | null;
}

export const Quote: React.FC<QuoteProps> = ({
  author,
  authorProfession,
  body,
}) => {
  return (
    <StyledQuote>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <div className="content">
            <div className="text-wrapper">
              <HTag type="h2" styleType="header1">
                &quot;
                {body}
                &quot;
              </HTag>
              {(author || authorProfession) && (
                <div className="AuthorWrapper">
                  {author && (
                    <Paragraph styleType="body14Medium" className="Author">
                      {author}
                    </Paragraph>
                  )}
                  {authorProfession && (
                    <Paragraph styleType="body14" className="Profession">
                      {authorProfession}
                    </Paragraph>
                  )}
                </div>
              )}
              <span />
            </div>
          </div>
        </GridChild>
      </Grid>
    </StyledQuote>
  );
};
