import styled from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';

interface RouterLinkStyledProps {
  $linkType?: 'special' | 'normal' | '' | null;
}

export const RouterLinkStyled = styled.span<RouterLinkStyledProps>`
  a {
    height: 100%;
    color: ${colors.black};
    border-bottom: ${({ $linkType }) =>
      $linkType === 'special' ? 'solid 1px #aaa' : 'none'};
    margin-top: ${({ $linkType }) =>
      $linkType === 'special' ? '30px' : 'unset'};
    margin-right: ${({ $linkType }) =>
      $linkType === 'special' ? '20px' : 'unset'};
    text-decoration: none;
    transition: all 0.2s;

    &:hover {
      border-color: #555;
      color: ${colors.blackHover};
      transition: all 0.2s;
    }

    &:focus {
      color: inherit;
      box-shadow: none !important;
      outline: 0;
      border: 3px auto -webkit-focus-ring-color !important;
    }

    &:active {
      color: inherit;
    }
  }
`;
