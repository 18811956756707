import Link from 'next/link';

import { RouterLinkStyled } from './RouterLink.styled';
interface RouterLinkProps {
  to?: string | null;
  name?: string | null;
  id?: string;
  onClick?: (...args: any[]) => any;
  dataValue?: string;
  className?: string;
  routerState?: object;
  search?: string;
  children?: React.ReactNode;
}
export const RouterLink: React.FC<RouterLinkProps> = ({
  dataValue,
  to,
  children,
  name,
  className,
  id,
  onClick,
  search,
}) => {
  const query: any = {};

  if (search) {
    query.search = search;
  }
  return (
    <RouterLinkStyled className={className} data-value={dataValue}>
      {to?.startsWith('http')
        ? (
          <a href={to} target="_blank" rel="noreferrer noopener">
            {children}
          </a>
          )
        : (
          <Link
            id={id}
            onClick={onClick}
            title={name ?? ''}
            rel="noreferrer noopener"
            href={{ pathname: to, query }}
          >
            {children}
          </Link>
          )}
    </RouterLinkStyled>
  );
};
