import dynamic from 'next/dynamic';
import Link from 'next/link';

import { StoreBrand } from '@hultafors/shared/types';

import ArrowRightSvg from '../../svg/arrow-right.svg';
import ExternalLinkSvg from '../../svg/external-link.svg';
import MailSvg from '../../svg/mail.svg';
import PhoneSvg from '../../svg/phone.svg';
import PlaceTagSvg from '../../svg/place-tag.svg';

import {
  Address,
  Contact,
  ContactLink,
  EmailAndPhoneWrapper,
  Inner,
  Label,
  OpeningHours,
  Separator,
  Stores,
  StoreSells,
  StoresWrapper,
  StoreText,
} from './StoreItem.styled';

const AccordionItem = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.AccordionItem),
);

interface StoreItemProps {
  label?: React.ReactNode;
  subLabel?: string | null;
  keyPrefix?: string | null;
  id?: string | null;
  coordinates?: {
    lng?: string | number;
    lat?: string | number;
  };
  address?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
  mapLink?: string | null;
  view_on_map?: string | null;
  emailAddress?: string | null;
  phone?: string | null;
  storeSells?: StoreBrand[];
  this_store_also_sells?: string | null;
  website?: string | null;
  visit_web_site?: string | null;
  local_web?: string | null;
  openingHours?: string | null;
}

export const StoreItem: React.FC<StoreItemProps> = ({
  label,
  address,
  zipCode,
  city,
  country,
  mapLink,
  view_on_map,
  emailAddress,
  phone,
  storeSells,
  this_store_also_sells,
  website,
  visit_web_site,
  local_web,
  openingHours,
  id,
}) => {
  return (
    <AccordionItem label={<Label>{label}</Label>} plus>
      <Inner>
        <Address>
          {address}
          {address && <br />}
          {zipCode}
          {' '}
          {city}
          {zipCode && city && <br />}
          {country}
        </Address>
        {openingHours && <OpeningHours>{openingHours}</OpeningHours>}
        {view_on_map && mapLink && (
          <ContactLink href={mapLink} target="_blank" rel="noopener noreferrer">
            <PlaceTagSvg
              width={16}
              height={16}
              aria-hidden={true}
              focusable={false}
            />
            {view_on_map}
          </ContactLink>
        )}

        {(emailAddress || phone) && (
          <EmailAndPhoneWrapper>
            {emailAddress && (
              <ContactLink href={`mailto:${emailAddress}`}>
                <MailSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
                {emailAddress}
              </ContactLink>
            )}
            {phone && (
              <ContactLink href={`tel:${phone}`}>
                <PhoneSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
                {phone}
              </ContactLink>
            )}
          </EmailAndPhoneWrapper>
        )}

        {!!storeSells?.length && (
          <StoreSells>
            <StoresWrapper>
              <StoreText>{this_store_also_sells}</StoreText>
              <Stores>
                {storeSells.map((brand, i) => {
                  return (
                    <div key={`Brand-${i}`}>
                      <Link
                        href={brand.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {brand.name}
                      </Link>
                      {i < storeSells.length - 1 && <Separator>|</Separator>}
                    </div>
                  );
                })}
              </Stores>
            </StoresWrapper>
          </StoreSells>
        )}

        {website && (
          <Contact>
            <ContactLink
              href={website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {visit_web_site}
              {visit_web_site && (
                <ExternalLinkSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
              )}
              {local_web}
              {local_web && (
                <ArrowRightSvg
                  width={16}
                  height={16}
                  aria-hidden={true}
                  focusable={false}
                />
              )}
            </ContactLink>
          </Contact>
        )}
      </Inner>
    </AccordionItem>
  );
};
