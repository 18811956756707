import { ProductPrice } from '@hultafors/shared/types';

import { isProductFavorite } from '@hultafors/toeguard/helpers';
import { useFavorites } from '@hultafors/toeguard/hooks';

import { Favorite } from '../Favorite/Favorite';
import { HTag } from '../HTag/HTag';
import { Paragraph } from '../Paragraph/Paragraph';

import { StyledProductDetailData } from './ProductDetailData.styled';

interface ProductDetailDataProps {
  name?: string;
  skuLabel?: string;
  sku?: string;
  price?: ProductPrice;
  rrpLabel?: string;
  isNew?: boolean;
  newLabel?: string;
  productForStorage?: any;
}

export const ProductDetailData: React.FC<ProductDetailDataProps> = ({
  name,
  skuLabel,
  sku,
  price,
  rrpLabel,
  isNew,
  newLabel,
  productForStorage,
}) => {
  const { toggleItem, favorites } = useFavorites();
  const renderName = () => {
    return (
      name && (
        <HTag type="h1" styleType="header3">
          {name}
        </HTag>
      )
    );
  };

  const renderFavorite = () => {
    return (
      <Favorite
        toggleFavorite={toggleItem}
        product={productForStorage}
        isFavorite={isProductFavorite(productForStorage?.sku, favorites)}
        inFavorites={false}
        bigStar
      />
    );
  };

  const renderArticleNumber = () => {
    return (
      sku && (
        <Paragraph styleType="body12" className="ArticleNumber">
          {skuLabel}
          {' '}
          {sku}
        </Paragraph>
      )
    );
  };

  const renderPrice = () => {
    return (
      price
      && price.value > 0 && (
        <div className="PriceWrapper">
          <Paragraph styleType="body16">{`${price.currency} ${price.value}`}</Paragraph>
          <Paragraph styleType="body12" className="RRPLabel">
            {rrpLabel}
          </Paragraph>
        </div>
      )
    );
  };
  return (
    <StyledProductDetailData>
      <div className="InfoFirstBlock">
        {isNew && (
          <Paragraph styleType="body14Medium" className="NewLabel">
            {newLabel}
          </Paragraph>
        )}
        {renderName()}
        {renderFavorite()}
        {renderArticleNumber()}
        {renderPrice()}
      </div>
    </StyledProductDetailData>
  );
};
