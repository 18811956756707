import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const ContentBlockStyled = styled.div`
  .HeroContent {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: ${spacing.medium};
    margin-bottom: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-top: ${spacing.large};
      margin-bottom: ${spacing.large};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    .HeroContent {
      padding-left: ${spacing.medium};
      padding-right: ${spacing.medium};
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    .HeroContent {
      padding-left: ${spacing.medium};
      padding-right: ${spacing.medium};
    }
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    .HeroContent {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .TextBoxText {
    margin-bottom: ${spacing.regular};
  }
`;
