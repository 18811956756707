import { StyledIconButtonWrapper } from './IconButtonWrapper.styled';
type IconButtonWrapperProps = React.ButtonHTMLAttributes<HTMLButtonElement>;
export const IconButtonWrapper: React.FC<IconButtonWrapperProps> = ({
  children,
  ...rest
}) => {
  return (
    <StyledIconButtonWrapper {...rest}>{children}</StyledIconButtonWrapper>
  );
};
