import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const PagePlugsStyled = styled.div`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.large} ${spacing.medium};
  }

  .Text {
    padding-top: ${spacing.xsmall};
  }
`;
