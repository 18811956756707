import styled, { css, RuleSet } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

export type HTagStyleType =
  | 'mega'
  | 'header5'
  | 'header4'
  | 'header3'
  | 'header2'
  | 'header1';

const hTagStyles = new Map<HTagStyleType, RuleSet>([
  [
    'mega',
    css`
      font-size: ${fontSizes.mega};
      line-height: ${lineHeights.mega};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontHeader}, sans-serif;
    `,
  ],
  [
    'header5',
    css`
      font-size: ${fontSizes.header5};
      line-height: ${lineHeights.header5};
      font-weight: ${fontWeights.fontBold};
      font-family: ${fontFamilies.fontRegular}, sans-serif;
      text-transform: none;
    `,
  ],
  [
    'header4',
    css`
      font-size: ${fontSizes.header4};
      line-height: ${lineHeights.header4};
      font-weight: ${fontWeights.fontBold};
      font-family: ${fontFamilies.fontRegular}, sans-serif;
    `,
  ],
  [
    'header3',
    css`
      font-size: ${fontSizes.header3};
      line-height: ${lineHeights.header3};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontHeader}, sans-serif;
    `,
  ],
  [
    'header2',
    css`
      font-size: ${fontSizes.header2};
      line-height: ${lineHeights.header2};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontHeader}, sans-serif;
    `,
  ],
  [
    'header1',
    css`
      font-size: ${fontSizes.header1};
      line-height: ${lineHeights.header1};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontHeader}, sans-serif;
    `,
  ],
]);

interface HTagResponsiveStyleType {
  breakpoint?: Breakpoint;
  styleType: HTagStyleType;
}

export type HTagStyleTypeProp = HTagStyleType | HTagResponsiveStyleType[];

interface HTagStyledProps {
  $center?: boolean;
  $styleType?: HTagStyleTypeProp;
}

function styleTypeMapper({ breakpoint, styleType }: HTagResponsiveStyleType) {
  if (!styleType) {
    return;
  }
  if (!breakpoint) {
    return hTagStyles.get(styleType);
  }
  return css`
    @media all and (min-width: ${breakpoints[breakpoint]}) {
      ${hTagStyles.get(styleType)}
    }
  `;
}

function getStyleType({ $styleType }: HTagStyledProps) {
  if (!$styleType) {
    return;
  }
  if (!Array.isArray($styleType)) {
    return hTagStyles.get($styleType);
  }
  return $styleType.map(styleTypeMapper);
}

export const StyledHTag = styled.h1<HTagStyledProps>`
  letter-spacing: 0.01em;
  color: ${colors.black};
  text-transform: uppercase;
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
  hyphens: auto;
  ${getStyleType}
`;
