import { Select } from '../Select/Select';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';

import { StyledRetailersAdditionalSelections } from './RetailersAdditionalSelections.styled';
interface RetailersAdditionalSelectionsProps {
  radiusAlternatives?: any[];
  radiusSelectChange: (...args: any[]) => any;
  selectedRadius?: number;
  radiusHelpText?: string;
  internationalSearchToggleChange: (...args: any[]) => any;
  isRetailerSearchExpandable?: boolean;
  isSearchInternational?: boolean;
  otherCountriesIncluded?: string;
}
export const RetailersAdditionalSelections: React.FC<
  RetailersAdditionalSelectionsProps
> = ({
  radiusSelectChange,
  internationalSearchToggleChange,
  isRetailerSearchExpandable,
  isSearchInternational,
  selectedRadius,
  radiusAlternatives,
  radiusHelpText,
  otherCountriesIncluded,
}) => {
  const RadiusSelect = () => {
    if (!isRetailerSearchExpandable) {
      return null;
    }
    return (
      <div className="RadiusSelectContainer">
        <Select
          id="radiusSelect"
          label={radiusHelpText}
          onChange={radiusSelectChange}
          selected={`${selectedRadius} km`}
          options={radiusAlternatives?.map(
            (radiusAlternative) => `${radiusAlternative.radiusValue} km`,
          )}
        />
      </div>
    );
  };
  const InternationalSearchToggle = () => {
    if (isSearchInternational) {
      return null;
    }
    return (
      <ToggleSwitch
        label={otherCountriesIncluded}
        checked={isSearchInternational}
        onChange={internationalSearchToggleChange}
      />
    );
  };
  return (
    <StyledRetailersAdditionalSelections>
      <div className="AdditionalSelections">
        {!!radiusAlternatives?.length && <RadiusSelect />}
        <InternationalSearchToggle />
      </div>
    </StyledRetailersAdditionalSelections>
  );
};
