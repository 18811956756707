import styled from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

export interface SlideInTopContainerProps {
  $open?: boolean;
}
export const SlideInTopContainer = styled.div<SlideInTopContainerProps>`
  transition: 0.2s;
  transform: ${({ $open }) => ($open ? 'translateY(0)' : 'translateY(-100%)')};
  width: 100%;
  padding: ${spacing.small} ${spacing.medium};
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${colors.white};
`;
