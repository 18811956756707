import { StyledFieldWrapper } from './FieldWrapper.styled';

export interface FieldWrapperProps {
  inline?: boolean;
  children?: React.ReactNode;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  children,
  inline,
}) => {
  return <StyledFieldWrapper $inline={inline}>{children}</StyledFieldWrapper>;
};
