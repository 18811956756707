import { useFavorites } from '@hultafors/toeguard/hooks';

import { Favorite } from '../Favorite/Favorite';

import { NavItemStyled } from './NavItem.styled';
interface NavItemProps {
  isFavorite?: boolean;
  toggle: (...args: any[]) => any;
  inTopMenu?: boolean;
}
export const NavItem: React.FC<NavItemProps> = ({ toggle }) => {
  const { favorites } = useFavorites();

  return (
    <NavItemStyled onClick={toggle}>
      <div>
        <Favorite isFavorite={favorites.length > 0} />
      </div>
    </NavItemStyled>
  );
};
