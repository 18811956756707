import { useRef, useState } from 'react';

import { VideoUrlFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import { StyledVideo } from './Video.styled';

interface VideoProps {
  src?: VideoUrlFragment | null;
  thumbnail?: VideoUrlFragment | null;
  autoPlay?: boolean;
}

export const Video: React.FC<VideoProps> = ({ src, thumbnail, autoPlay }) => {
  const [paused, setPaused] = useState(true);
  const videoPlayer = useRef<HTMLVideoElement>(null);
  const handleOnPlay = () => {
    videoPlayer.current?.setAttribute('controls', 'true');
    setPaused(false);
  };
  function playVideo() {
    videoPlayer.current?.play();
    handleOnPlay();
  }
  if (!src?.url) {
    return null;
  }
  return (
    <StyledVideo>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 10, start: 2 },
          ]}
        >
          <div className="video-wrapper">
            <video
              ref={videoPlayer}
              poster={thumbnail && thumbnail.url ? thumbnail.url : ''}
              autoPlay={autoPlay}
              onPlay={handleOnPlay}
            >
              <source src={src.url} />
              <p>
                Your browser doesn&apos;t support HTML5 video. Here is a
                {' '}
                <a href={src.url}>link to the video</a>
                {' '}
                instead.
              </p>
            </video>
            <div className="controls">
              <button
                title="play"
                onClick={playVideo}
                className={paused ? 'visible' : ''}
              >
                <svg
                  fill="none"
                  height="14"
                  viewBox="0 0 12 14"
                  width="12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m12 7-11.9999997 6.9282.00000061-13.8564036z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="description">
            {src.title && <p className="video-title">{src.title}</p>}
          </div>
        </GridChild>
      </Grid>
    </StyledVideo>
  );
};
