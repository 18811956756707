import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import { Spacing, spacing } from '@hultafors/toeguard/helpers';

export interface GridRowGap {
  rowGap: number | Spacing;
  breakpoint?: Breakpoint;
}

export interface GridColumn {
  columns: number;
  breakpoint?: Breakpoint;
}

export interface GridColumnGap {
  columnGap: Spacing;
  breakpoint?: Breakpoint;
}

export type GridColumnsProp =
  | CSSProperties['gridTemplateColumns']
  | GridColumn[];
export type GridRowGapProp = number | Spacing | GridRowGap[];
export type GridColumnGapProp = number | Spacing | GridColumnGap[];

interface StyledGridProps {
  $autoFlow?: CSSProperties['gridAutoFlow'];
  $align?: CSSProperties['alignItems'];
  $margins?: CSSProperties['margin'];
  $maxWidth?: CSSProperties['maxWidth'];
  $minColumnWidth?: CSSProperties['gridTemplateColumns'];
  $center?: boolean;
  $rowGap?: GridRowGapProp;
  $columns?: GridColumnsProp;
  $columnGap?: GridColumnGapProp;
}

function getRowGap({ $rowGap }: StyledGridProps) {
  if (!$rowGap) {
    return;
  }
  if (!Array.isArray($rowGap)) {
    const value
      = typeof $rowGap === 'number' ? `${$rowGap}px` : spacing[$rowGap];
    return css`
      row-gap: ${value};
    `;
  }
  return $rowGap.map(({ breakpoint, rowGap }) => {
    const value = typeof rowGap === 'number' ? `${rowGap}px` : spacing[rowGap];
    if (!breakpoint) {
      return css`
        row-gap: ${value};
      `;
    }
    return css`
      @media all and (min-width: ${breakpoints[breakpoint]}) {
        row-gap: ${value};
      }
    `;
  });
}

function getColumnGap({ $columnGap }: StyledGridProps) {
  if (!$columnGap) {
    return;
  }
  if (!Array.isArray($columnGap)) {
    const value
      = typeof $columnGap === 'number' ? `${$columnGap}px` : spacing[$columnGap];
    return css`
      column-gap: ${value};
    `;
  }
  return $columnGap.map(({ breakpoint, columnGap }) => {
    const value
      = typeof columnGap === 'number' ? `${columnGap}px` : spacing[columnGap];
    if (!breakpoint) {
      return css`
        column-gap: ${value};
      `;
    }
    return css`
      @media all and (min-width: ${breakpoints[breakpoint]}) {
        column-gap: ${value};
      }
    `;
  });
}

function getColumns({ $columns, $minColumnWidth }: StyledGridProps) {
  if (!Array.isArray($columns)) {
    return css`
      grid-template-columns: repeat(
        ${$columns},
        ${$minColumnWidth
          ? `minmax(${$minColumnWidth}, 1fr)`
          : 'minmax(0, 1fr)'}
      );
    `;
  }
  return $columns.map(({ breakpoint, columns }) => {
    const value = css`
      grid-template-columns: repeat(${columns}, minmax(0, 1fr));
    `;
    if (!breakpoint) {
      return value;
    }
    return css`
      @media all and (min-width: ${breakpoints[breakpoint]}) {
        ${value};
      }
    `;
  });
}

export const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-auto-flow: ${({ $autoFlow }) => $autoFlow};
  place-items: ${({ $align }) => $align}
    ${({ $align }) => ($align === 'flex-start' ? 'stretch' : $align)};
  justify-content: ${({ $center }) => ($center ? 'center' : 'initial')};
  margin: ${({ $margins }) => $margins};
  max-width: ${({ $maxWidth }) => $maxWidth};

  ${getColumns};
  ${getRowGap};
  ${getColumnGap};
`;
