import styled from 'styled-components';

import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

export const ProductInformationTabsStyled = styled.div`
  button {
    padding: 0;
  }

  .OverviewText {
    margin: 0;
  }

  .DetailInfo {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      font-size: ${fontSizes.body16};
      font-family: ${fontFamilies.fontRegular};
    }
  }
`;
