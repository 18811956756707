import { ToeguardProduct } from '@hultafors/toeguard/api';

import { EmptyProducts } from '../EmptyProducts/EmptyProducts';
import { Product } from '../Product/Product';
interface ProductsProps {
  products: ToeguardProduct[];
  badgeText: string;
  isLoading: boolean;
  numberOfItems?: number;
  rrpLabel?: string;
  onClick?: (...args: any[]) => any;
  routerState?: object;
}

export const Products: React.FC<ProductsProps> = ({
  numberOfItems = 12,
  ...props
}) => {
  return (
    <>
      {props.isLoading
        ? (
          <EmptyProducts pageSize={numberOfItems} />
          )
        : (
          <>
            {props.products.map((product, i) => (
              <Product
                key={`p-${i}`}
                product={product}
                className="Product"
                badgeText={props.badgeText}
                rrpLabel={props.rrpLabel}
                onClick={props.onClick}
              />
            ))}
          </>
          )}
    </>
  );
};
