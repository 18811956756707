import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

export const FooterStyled = styled.footer`
  width: 100%;
  margin-top: auto;
  background-color: ${colors.gray4};
  color: ${colors.black};

  .Grid {
    position: relative;
  }

  section {
    padding: ${spacing.regular} ${spacing.small} ${spacing.small};
    margin: 0;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: ${spacing.regular} ${spacing.medium} ${spacing.small};
    }
  }

  .LogoWrapper {
    display: block;
    margin-bottom: ${spacing.medium};
  }

  .FooterFunctions {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    margin-bottom: ${spacing.regular};
    position: absolute;
    top: 2px;
    right: 0;

    .PartnerLoginLink {
      display: flex;
      align-items: center;
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body10};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontRegular};
      color: ${colors.hellBlue};
      text-decoration: none;

      svg {
        margin-left: ${spacing.xsmall};

        path {
          stroke: ${colors.hellBlue};
        }
      }

      &:hover {
        color: ${colors.hellBlueHover};

        svg {
          path {
            stroke: ${colors.hellBlueHover};
          }
        }
      }
    }
  }
`;

export const MiddleSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding-top: ${spacing.small};
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex-basis: 45%;
  text-align: left;

  .IntroText {
    padding-bottom: ${spacing.regular};
  }

  p,
  a {
    margin-top: 0;
    max-width: 600px;
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body10};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};
    color: ${colors.black};

    &:hover {
      color: ${colors.blackHover};
    }
  }
`;

export const SubscribeAreaWrapper = styled.div`
  flex-basis: 25%;
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  font-size: ${fontSizes.body14};
  line-height: ${lineHeights.body14};
  font-weight: ${fontWeights.fontRegular};
  font-family: ${fontFamilies.fontRegular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
    align-items: center;
  }

  .BottomSectionLinks a {
    text-decoration: none;
    color: ${colors.black};
  }

  .SocialMedia a {
    width: 32px;
    height: 32px;
  }

  .SocialMedia a:not(:last-of-type) {
    margin-right: ${spacing.small};
  }

  .BottomSectionLinks a:hover {
    color: ${colors.gray1};
  }

  .SocialMedia {
    display: flex;
    padding-bottom: ${spacing.medium};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-bottom: 0;
    }

    img {
      width: 100%;
    }
  }
`;

export const CookieSettings = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  background: transparent;
  font-size: ${fontSizes.body14};
  line-height: ${lineHeights.body14};
  font-weight: ${fontWeights.fontRegular};
  font-family: ${fontFamilies.fontRegular};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${colors.gray1};
  }
`;
