export const spacing = {
  XL: '192px',
  large: '96px',
  medium: '48px',
  regular: '24px',
  small: '16px',
  xsmall: '8px',
  tiny: '8px',

  pagePadding: '30px',
  pagePaddingMobile: '20px',

  // Not sure is this are accurate
  mobileTop: '72px',
  menuHeight: '56px',
} as const;
export type Spacing = keyof typeof spacing;
