import styled, { css } from 'styled-components';

interface LinkBlockStyledProps {
  $square?: boolean;
}
export const LinkBlockStyled = styled.div<LinkBlockStyledProps>`
  display: flex;
  justify-content: center;

  ${({ $square }) =>
    $square
    && css`
      a {
        border-radius: 0;
        height: 56px;
        padding: 0 32px;
      }
    `}
`;
