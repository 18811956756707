import { CSSProperties } from 'styled-components';

import { GridChildColumnSpanProp, StyledGridChild } from './GridChild.styled';
interface GridChildProps {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  align?: CSSProperties['justifySelf'];
  alignSelf?: CSSProperties['alignSelf'];
  columnSpan?: GridChildColumnSpanProp;
  padding?: CSSProperties['padding'];
  rowSpan?: CSSProperties['gridRowEnd'];
  rowStart?: CSSProperties['gridRowStart'];
}

export const GridChild: React.FC<GridChildProps> = ({
  className,
  children,
  style,
  align = 'stretch',
  alignSelf,
  columnSpan,
  padding,
  rowSpan,
  rowStart,
}) => {
  return (
    <StyledGridChild
      className={className}
      style={style}
      $align={align}
      $alignSelf={alignSelf}
      $columnSpan={columnSpan}
      $padding={padding}
      $rowSpan={rowSpan}
      $rowStart={rowStart}
    >
      {children}
    </StyledGridChild>
  );
};
