import { FavoriteStyled } from './Favorite.styled';
interface FavoriteProps {
  toggleFavorite?(...args: any[]): void;
  product?: any;
  inFavorites?: boolean;
  isFavorite: boolean;
  bigStar?: boolean;
}
export const Favorite: React.FC<FavoriteProps> = ({
  toggleFavorite,
  product,
  inFavorites,
  isFavorite,
  bigStar,
}) => {
  const renderFavorite = () => {
    return (
      <svg
        className="Favorite"
        fill="none"
        height="24"
        width="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 3.17665L14.6374 8.79501L14.7635 9.06373L15.0598 9.08163L20.9583 9.43798L16.6223 14.4379L16.4688 14.6149L16.5065 14.846L17.5207 21.0641L12.242 18.145L12 18.0112L11.758 18.145L6.47926 21.0641L7.49348 14.846L7.53118 14.6149L7.37774 14.4379L3.04166 9.43798L8.94015 9.08163L9.23647 9.06373L9.36261 8.79501L12 3.17665Z"
          stroke="#111111"
        />
      </svg>
    );
  };
  const renderBigFavorite = () => {
    return renderFavorite();
  };
  const renderRemoveFavoriteIcon = () => {
    return (
      <svg
        className="Remove Favorite"
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="8" cy="8" r="6.5" fill="white" stroke="#000000" />
        <path d="M5 5L11 11" stroke="#000000" strokeLinecap="round" />
        <path d="M11 5L5 11" stroke="#000000" strokeLinecap="round" />
      </svg>
    );
  };
  return (
    <FavoriteStyled
      className={`Favorite ${isFavorite ? 'Active ' : ''} ${
        inFavorites ? 'InFavorites' : ''
      }`}
      onClick={() => toggleFavorite?.(product, isFavorite)}
    >
      {!inFavorites && !bigStar && renderFavorite()}
      {!inFavorites && bigStar && renderBigFavorite()}
      {inFavorites && renderRemoveFavoriteIcon()}
    </FavoriteStyled>
  );
};
