import styled, { css } from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

interface BadgeStyledProps {
  $inline?: boolean;
  $round?: boolean;
  $small?: boolean;
  $tiny?: boolean;
  $isNew?: boolean;
  $premium?: boolean;
  $blue?: boolean;
}

export const BadgeStyled = styled.div<BadgeStyledProps>`
  position: ${({ $inline }) => ($inline ? 'relative' : 'absolute')};
  display: ${({ $inline }) => ($inline ? 'inline-block' : 'block')};
  font-size: ${fontSizes.body10};
  text-align: center;
  line-height: 17px;
  width: 20px;
  height: 20px;
  text-transform: uppercase;
  margin-left: ${spacing.xsmall};
  margin-bottom: ${({ $inline }) => ($inline ? '12px' : '0')};
  letter-spacing: 0;

  ${({ $round, $blue }) =>
    $round
    && css`
      margin: 0;
      padding: 0;
      border-radius: 27px;
      background-color: ${colors.white};
      border: 1px solid;
      border-color: ${$blue ? colors.hellBlue : colors.black};
      color: ${$blue ? colors.hellBlue : colors.black};

      &::before {
        display: none;
      }
    `}

  ${({ $small }) =>
    $small
    && css`
      width: 32px;
      height: 32px;
      line-height: 32px;
    `}

  ${({ $tiny }) =>
    $tiny
    && css`
      width: 16px;
      height: 16px;
      line-height: 14px;
      letter-spacing: 0;
    `}

${({ $isNew }) =>
    $isNew
    && css`
      display: flex;
      align-items: center;
      margin-left: ${spacing.small};
      letter-spacing: initial;
      font-size: ${fontSizes.body12};
      line-height: ${lineHeights.body12};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegular};
      text-transform: none;
      color: ${colors.hellSalmon};
    `}

  ${({ $premium }) =>
    $premium
    && css`
      display: flex;
      align-items: center;
      background: ${colors.black};
      color: ${colors.white};
      padding: 0 5px;
      letter-spacing: 0.05rem;
    `}
`;
