import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const ProductDetailView = styled.div`
  .TopProductInformation {
    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      padding-left: ${spacing.medium};
      padding-right: ${spacing.medium};
    }
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    .GridChild1 {
      grid-row-start: 1;
    }
    .GridChild2 {
      grid-row-start: 1;
      grid-row-end: 4;
    }
    .GridChild3 {
      grid-row-start: 2;
    }
  }
`;
