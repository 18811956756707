import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';

export const StyledMarkdown = styled(Markdown)`
  a {
    text-decoration: none;
    text-decoration: underline;
    color: ${colors.black};

    &:hover {
      color: ${colors.blackHover};
    }
  }
`;
