import { transformProduct } from '../products';

import { LocalStorage } from './local-storage';

const KEY = 'tg_favorites';

export const getFavorites = (): any[] => {
  return LocalStorage?.get(KEY) ?? [];
};

/**
 * @desc Toggles a favorite product. Product is transformed to only needed properties.
 * @param object product - product representation from list view or detail page
 * @param boolean isFavorite - true would indicate product is already a favorite
 * @return favorites (array)
 */
export const toggleFavorite = (product: any, isFavorite?: boolean) => {
  const result = toggleHelper(product, isFavorite);
  LocalStorage?.set(KEY, result);
  return result;
};

const toggleHelper = (product: any, isFavorite?: boolean) => {
  let result = [];
  const items = getFavorites();

  if (items) {
    if (isFavorite) {
      result = removeFavorite(items, product);
    } else {
      result = addFavorite(items, product);
    }
  } else {
    result = addFavorite(items, product);
  }
  return result;
};

/**
 * @desc Takes array of products presumably from local storage. Transforms product object from API to compressed version and adds to list
 * @private Public for tests only
 * @param {array} items cart items
 * @param {object} product
 * @returns {array} cart items
 */
export const addFavorite = (items: any[], product: any) => {
  let result: any[] = [];
  if (!product) {
    return items ? [...items] : result;
  }

  if (items) {
    const exist = items.find((x) => x.sku === product.sku);
    if (!exist) {
      const transformedProduct = transformProduct(product);
      if (transformedProduct) {
        result = [...items, transformedProduct];
      }
    } else {
      result = [...items];
    }
  } else {
    const transformedProduct = transformProduct(product);

    if (transformedProduct) {
      result = [transformProduct(product)];
    }
  }

  return result;
};

/**
 * @desc Takes array of products presumably from local storage. Removes second param product from array and return it
 * @private Public for tests only
 * @param {array} items
 * @param {object} product
 * @returns {array} cart items
 */
export const removeFavorite = (items: any[], product: any) => {
  let result = [];
  if (items) {
    if (product?.sku) {
      result = items.filter((x) => x.sku !== product.sku);
    } else {
      result = [...items];
    }
  }
  return result;
};

/**
 * @desc Removes all favorites
 * @fires Function accesses local storage
 */
export const removeFavorites = () => {
  LocalStorage?.remove(KEY);
};

/**
 * @desc Checks id product is in favorites list ( local storage )
 * @param string sku - product sku
 * @param array items - list of favorites
 * @return boolean
 */
export const isProductFavorite = (sku: string, items: any[]) => {
  if (items) {
    return items.find((x) => x.sku === sku) ? true : false;
  }
  return false;
};
