import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

export const ProductDetailInformationStyled = styled.div`
  padding: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    padding: 0;
    padding-bottom: ${spacing.medium};
    margin-top: 0;
  }

  position: relative;

  h1 {
    padding-right: ${spacing.regular};
  }

  .InfoFirstBlock {
    position: relative;
    margin-bottom: ${spacing.medium};

    .NewLabel {
      position: absolute;
      top: -${spacing.regular};
      color: ${colors.hellSalmon};
    }
  }

  .Favorite {
    position: absolute;
    top: 0;
    right: 0;
  }

  .FeatureList {
    margin: 0;
    margin-bottom: ${spacing.medium};
    padding: 0;

    ul {
      margin: 0;
      padding: 0;
      padding-left: 1em;
    }

    li {
      color: ${colors.black};
      font-style: normal;
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body16};
      font-family: ${fontFamilies.fontRegular};
    }
  }

  .Placeholder {
    height: ${spacing.regular};
  }

  .SizeGuideButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: ${spacing.small};

    .SizeGuideWrapper {
      display: flex;
      justify-content: flex-end;

      svg {
        margin-left: ${spacing.xsmall};
        transition: all 0.3s ease;
      }

      &:hover {
        cursor: pointer;

        svg {
          transform: translateX(4px);
        }
      }
    }

    p {
      margin: 0;
      font-style: normal;
      font-weight: ${fontWeights.fontRegular};
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.bodyXS};
    }
  }

  .ChooseSizeButton {
    button:first-of-type {
      margin-bottom: ${spacing.small};
    }

    button:last-of-type {
      margin-bottom: ${spacing.medium};
    }
  }

  .Materials {
    padding-top: ${spacing.medium};
    border-top: 1px solid ${colors.gray4};

    h3 {
      font-size: ${fontSizes.header3};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegular};
      margin-bottom: ${spacing.regular};
    }

    img {
      height: 48px;
      width: auto;
      max-width: 100px;
      margin: 0 0 ${spacing.small} 0;
    }

    p {
      margin: 0;
    }

    .MaterialItem {
      margin-bottom: ${spacing.regular};
    }
  }

  .MaterialsLink {
    margin-top: ${spacing.regular};

    a {
      font-size: ${fontSizes.body};
    }
  }

  .MaterialsLink:hover {
    &::after {
      transition: all 0.3s ease;
      transform: translateX(4px);
    }
  }
`;
