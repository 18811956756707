import styled, { css } from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

interface ArrowLinkStyledProps {
  $rotateIcon?: boolean;
  $smallText?: boolean;
  $color?: string;
}

export const ArrowLinkStyled = styled.div<ArrowLinkStyledProps>`
  .Wrapper {
    display: flex;
    align-items: center;

    a,
    p {
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body16};
      font-weight: ${fontWeights.fontMedium};
      font-family: ${fontFamilies.fontRegular};
    }

    ${({ $smallText }) =>
      $smallText
      && css`
        a,
        p {
          font-size: ${fontSizes.body12};
          line-height: ${lineHeights.body12};
          font-weight: ${fontWeights.fontMedium};
          font-family: ${fontFamilies.fontRegular};
        }
      `}
    svg {
      margin-right: ${spacing.xsmall};
      transform: ${({ $rotateIcon }) =>
        $rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)'};
    }

    &.black {
      svg {
        path {
          stroke: ${colors.black};
        }
      }

      a,
      p {
        color: ${colors.black};
      }
    }

    &.blue {
      svg {
        path {
          stroke: ${colors.hellBlue};
        }
      }

      a,
      p {
        color: ${colors.hellBlue};
      }
    }

    &.white {
      svg {
        path {
          stroke: ${colors.white};
        }
      }

      a,
      p {
        color: ${colors.white};
      }
    }

    &.black:hover {
      a,
      p {
        color: ${colors.blackHover};
      }

      svg {
        path {
          stroke: ${colors.blackHover};
        }
      }
    }

    &.blue:hover {
      svg {
        path {
          stroke: ${colors.hellBlueHover};
        }
      }

      a,
      p {
        cursor: pointer;
        color: ${colors.hellBlueHover};
      }
    }

    &.white:hover {
      a,
      p {
        color: ${colors.gray4};
      }

      svg {
        path {
          stroke: ${colors.gray4};
        }
      }
    }
  }
`;
