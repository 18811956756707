import styled from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';

export const FindRetailersListStyled = styled.div`
  margin-bottom: ${spacing.medium};

  &:empty {
    display: none;
  }

  .CollapseHeader {
    height: 72px;
    cursor: pointer;
    line-height: ${lineHeights.header3};
    border-bottom: 1px solid ${colors.gray4};
    transition: border-color 0.4s ease;

    &.isOpen {
      border-color: rgb(0 0 0 / 0%);
    }

    h2 {
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body16};
      color: ${colors.black};
    }

    .SubLabel {
      font-size: ${fontSizes.body12};
      line-height: ${lineHeights.body12};
    }

    &::after {
      content: '';
      position: absolute;
      display: inline-flex;
      right: 0;
      top: 26px;
      width: 16px;
      height: 16px;
      background: url('/assets/gfx/open_2_24.svg') no-repeat center center;
      transform: translateY(-50%);
      transition: transform 0.4s;
    }

    &.isOpen::after {
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .CollapseContent {
    padding: 0;
    background-color: ${colors.gray5};

    p {
      margin-bottom: ${spacing.small};
      font-size: ${fontSizes.body16};
      line-height: ${lineHeights.body16};
    }
  }
`;
