import styled from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';
export const DownloadFileBoxStyled = styled.span`
  a {
    color: ${colors.black};
    position: relative;
    background-color: ${colors.gray5};
    width: 100%;
    max-width: 657px;
    height: 64px;
    display: flex;
    flex-direction: row;
    text-decoration: none;
    padding: ${spacing.small} 0;
    margin-bottom: ${spacing.xsmall};

    &:hover {
      cursor: pointer;
      background-color: ${colors.gray6};
    }
  }

  .Icon {
    color: ${colors.black};
    position: absolute;

    &:first-of-type {
      left: ${spacing.xsmall};
      top: ${spacing.small};
    }

    &:last-of-type {
      right: ${spacing.xsmall};
      top: ${spacing.small};
    }

    height: 16px;
    width: 16px;
  }

  .TextWrapper {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    text-align: left;
  }

  p {
    &.Label {
      color: ${colors.black};
      font-style: normal;
      font-weight: ${fontWeights.fontMedium};
      font-size: ${fontSizes.body16};
      font-family: ${fontFamilies.fontRegular};
      line-height: ${lineHeights.body10};
      margin: 0;
    }

    &.Size {
      color: ${colors.hellSalmon};
      margin: 0;
    }
  }
`;
