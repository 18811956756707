import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const StyledImage = styled.div`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.medium} 0;
  }

  img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }

  p {
    padding-top: ${spacing.xsmall};
  }
`;
