import {
  DynamicContentItem,
  isBlueInfoBox,
  isBulletList,
  isDotDigitalForm,
  isGreyInfoBox,
  isImageBlock,
  isImageSlider,
  isQuote,
  isTextBlock,
  isTwoImageBlock,
  isVideoBlock,
} from '@hultafors/toeguard/types';

import { BlueInfoBox } from '../BlueInfoBox/BlueInfoBox';
import { BulletList } from '../BulletList/BulletList';
import { DualImage } from '../DualImage/DualImage';
import { GreyInfoBox } from '../GreyInfoBox/GreyInfoBox';
import { IFrameContainer } from '../IFrameContainer/IFrameContainer';
import { ImageBlock } from '../ImageBlock/ImageBlock';
import { ImageSlider } from '../ImageSlider/ImageSlider';
import { Quote } from '../Quote/Quote';
import { TextBlock } from '../TextBlock/TextBlock';
import { Video } from '../Video/Video';

import { StyledDynamicContent } from './DynamicContent.styled';

interface DynamicContentProps {
  content?: DynamicContentItem[];
}

export const DynamicContent: React.FC<DynamicContentProps> = ({ content }) => {
  if (!content?.length) {
    return null;
  }

  const contentMapper = (item: DynamicContentItem) => {
    const key = `DynamicContent-${item.id}`;
    if (isBlueInfoBox(item)) {
      return <BlueInfoBox key={key} body={item.body} />;
    }
    if (isBulletList(item)) {
      return (
        <BulletList
          key={key}
          title={item.title}
          body={item.list}
          isBig={item.isBigBulletList}
        />
      );
    }
    if (isDotDigitalForm(item)) {
      return (
        <IFrameContainer
          key={key}
          title="Test"
          src={item.formUrl ?? ''}
          height={`${item.formHeight}px`}
        />
      );
    }
    if (isGreyInfoBox(item)) {
      return <GreyInfoBox key={key} title={item.title} body={item.body} />;
    }
    if (isImageBlock(item)) {
      return <ImageBlock key={key} image={item.image} label={item.label} />;
    }
    if (isImageSlider(item)) {
      return <ImageSlider key={key} images={item.images} />;
    }
    if (isQuote(item)) {
      return (
        <Quote
          key={key}
          body={item.body}
          author={item.author}
          authorProfession={item.authorProfession}
        />
      );
    }
    if (isTextBlock(item)) {
      return (
        <TextBlock
          key={key}
          body={item.body}
          bigHeader={item.bigHeader}
          title={item.title}
          className="TextBlock"
        />
      );
    }
    if (isTwoImageBlock(item)) {
      return (
        <DualImage
          key={key}
          imageOne={item.images[0]}
          imageTwo={item.images[1]}
        />
      );
    }
    if (isVideoBlock(item)) {
      return (
        <Video key={key} src={item.video} thumbnail={item.videoThumbnail} />
      );
    }
    return null;
  };

  return (
    <StyledDynamicContent>{content.map(contentMapper)}</StyledDynamicContent>
  );
};
