import { Button } from '../Button/Button';
import { ButtonVariant } from '../Button/Button.styled';

import { LinkBlockStyled } from './LinkBlock.styled';
interface LinkBlockProps {
  linkText?: string | null;
  linkUrl?: string | null;
  buttonVariant?: ButtonVariant;
  className?: string;
  external?: boolean;
  square?: boolean;
}
export const LinkBlock: React.FC<LinkBlockProps> = ({
  className,
  linkText,
  linkUrl,
  buttonVariant,
  square,
}) => {
  return (
    <LinkBlockStyled className={className} $square={square}>
      {linkUrl && linkText && (
        <Button to={linkUrl} variant={buttonVariant}>
          {linkText}
        </Button>
      )}
    </LinkBlockStyled>
  );
};
