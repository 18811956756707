import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import { Label } from '../Label/Label';

import { StyledSelect, StyledSelectContainer } from './Select.styled';
interface SelectProps {
  id: string;
  inline?: boolean;
  options?: string[];
  selected: string;
  label?: string | null;
  wrapperProps?: object;
  children?: React.ReactNode;
  onChange?(...args: any[]): void;
}
export const Select: React.FC<SelectProps> = ({
  children,
  label,
  id,
  inline,
  options = [],
  selected,
  onChange,
  wrapperProps,
}) => {
  return (
    <FieldWrapper inline={inline} {...wrapperProps}>
      <StyledSelectContainer>
        <Label htmlFor={id}>{label}</Label>
        <StyledSelect id={id} value={selected} onChange={onChange}>
          {children
            ? children // If children are provided, they will take precedence over the "options" prop
            : options?.map((option) => (
              <option
                id={option}
                value={option}
                key={`select.${id}.${option}`}
              >
                {option}
              </option>
            ))}
        </StyledSelect>
      </StyledSelectContainer>
    </FieldWrapper>
  );
};
