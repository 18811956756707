import { Store } from '@hultafors/shared/types';

import { OnlineStoreListStyled } from './OnlineStoreList.styled';

interface OnlineStoreListProps {
  stores: Store[];
}

export const OnlineStoreList: React.FC<OnlineStoreListProps> = ({ stores }) => {
  const storeMapper = ({ id, data }: Store) => {
    if (data.webSite) {
      return (
        <a
          key={`Online-Store-${id}`}
          href={data.webSite}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.companyName || data.webSite}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 7.71423V21.9999H16.2857"
              stroke="#111111"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 2H22V11"
              stroke="#111111"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 2L11 13"
              stroke="#111111"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </a>
      );
    }
    if (data.companyName) {
      return <span key={`Online-Store-${id}`}>{data.companyName}</span>;
    }
    return null;
  };
  return (
    <OnlineStoreListStyled>{stores.map(storeMapper)}</OnlineStoreListStyled>
  );
};
