import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const ImageSliderStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: ${spacing.medium} 0;
  padding: 0 ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.large} 0;
  }

  .ImgWrapper {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
  }

  .GridChildSliderWrapper {
    position: relative;
  }

  .Counter {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .slick-slider {
    position: relative;
    display: block;
    height: 100%;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    padding-bottom: 32px;
  }

  .slick-prev,
  .slick-next {
    border: none;
    outline: 0;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }
  }

  .slick-slider.article {
    .slick-prev,
    .slick-next {
      width: ${spacing.small};
      height: ${spacing.regular};
      position: absolute;
      bottom: 0;
      z-index: 1;
      background: url('/assets/gfx/image-slider-arrow-fill.svg') center center
        no-repeat;
      background-position: center;
    }

    .slick-prev {
      left: calc(50% - 51px);
    }

    .slick-next {
      right: calc(50% - 51px);
      transform: rotate(180deg);
    }

    .ImgWrapper img {
      width: 100%;
      max-width: 100%;
      max-height: 800px;
      object-fit: cover;
    }

    .slick-slide img {
      display: block;
    }

    .slick-slide.slick-loading img {
      display: none;
    }

    .slick-slide.dragging img {
      pointer-events: none;
    }

    .ImgWrapper {
      display: flex !important;
      justify-content: center;
      background-color: #fff;
    }
  }

  .slick-list {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .slick-list:focus {
    outline: none;
  }

  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: 100%;
    transform: translate3d(0, 0, 0);
  }

  .slick-track::before,
  .slick-track::after {
    display: table;
    content: '';
  }

  .slick-track::after {
    clear: both;
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;
