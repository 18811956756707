import { ContactPageFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';

import {
  Block,
  ContactInformationStyled,
  Row,
  Title,
} from './ContactInformation.styled';

type ContactInformationProps = Pick<
  ContactPageFragment,
  | 'addressHeader'
  | 'phoneHeader'
  | 'emailHeader'
  | 'localContactHeader'
  | 'companyName'
  | 'companyAddress'
  | 'companyCountry'
  | 'companyEmail'
  | 'phoneNumber'
  | 'localContactName'
  | 'localContactZipcode'
  | 'localContactAddress'
  | 'localContactCity'
  | 'localContactCountry'
>;

export const ContactInformation: React.FC<ContactInformationProps> = ({
  addressHeader,
  phoneHeader,
  emailHeader,
  localContactHeader,
  companyName,
  companyAddress,
  companyCountry,
  phoneNumber,
  companyEmail,
  localContactName,
  localContactZipcode,
  localContactAddress,
  localContactCity,
  localContactCountry,
}) => {
  return (
    <ContactInformationStyled>
      <Grid columnGap={0}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          {(companyName || companyAddress || companyCountry) && (
            <Block>
              {addressHeader && <Title>{addressHeader}</Title>}
              {companyName && <Row>{companyName}</Row>}
              {companyAddress && <Row>{companyAddress}</Row>}
              {companyCountry && <Row>{companyCountry}</Row>}
            </Block>
          )}
          {phoneNumber && (
            <Block>
              {phoneHeader && <Title>{phoneHeader}</Title>}
              <Row>
                <a href={`tel:${phoneNumber.replace(/( |-|\)|\)|,|.)/g, '')}`}>
                  {phoneNumber}
                </a>
              </Row>
            </Block>
          )}
          {companyEmail && (
            <Block>
              {emailHeader && <Title>{emailHeader}</Title>}
              <Row>
                <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
              </Row>
            </Block>
          )}
          {(localContactName
          || localContactZipcode
          || localContactAddress
          || localContactCity
          || localContactCountry) && (
            <Block>
              {localContactHeader && <Title>{localContactHeader}</Title>}
              {localContactName && <Row>{localContactName}</Row>}
              {localContactZipcode && <Row>{localContactZipcode}</Row>}
              {localContactAddress && <Row>{localContactAddress}</Row>}
              {localContactCity && <Row>{localContactCity}</Row>}
              {localContactCountry && <Row>{localContactCountry}</Row>}
            </Block>
          )}
        </GridChild>
      </Grid>
    </ContactInformationStyled>
  );
};
