import styled from 'styled-components';

export const ProductImageStyled = styled.div`
  width: 100%;
  overflow: hidden;
  position: absolute;
  inset: 0;
  z-index: 2;

  .ImageWrapper {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      transform: scale(1);
      transition: transform 0.3s ease-in-out;
    }
  }

  a {
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }
`;
