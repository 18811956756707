import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';

export const StyledInformationListHero = styled.div`
  margin-bottom: ${spacing.medium};
  border-bottom: none;
  padding: ${spacing.medium} ${spacing.small};

  .HeroText {
    h2 {
      padding-top: ${spacing.small};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin-bottom: ${spacing.large};
    border-bottom: 1px solid ${colors.gray4};
    padding: 0 ${spacing.medium};

    .HeroText {
      padding-top: ${spacing.medium};
    }
  }

  &.NoMarginBottom {
    margin-bottom: 0;
    padding-bottom: 0;

    .HeroText {
      padding-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      border-bottom: none;
    }
  }

  &.NoBorderBottom {
    border-bottom: none;
  }
`;
