import Markdown from 'markdown-to-jsx';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

import { StyledTextBlock } from './TextBlock.styled';
interface TextBlockProps {
  body?: string | null;
  title?: string | null;
  bigHeader?: boolean | null;
  className?: string;
}
export const TextBlock: React.FC<TextBlockProps> = ({
  className,
  bigHeader,
  body,
  title,
}) => {
  return (
    <StyledTextBlock className={className}>
      <Grid columnGap={0}>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          {title && (
            <HTag
              type={bigHeader ? 'h2' : 'h3'}
              styleType={bigHeader ? 'header3' : 'header4'}
            >
              {title}
            </HTag>
          )}
          <Markdown
            options={{ forceBlock: true }}
            className="markdown-wrapper"
          >
            {body ?? ''}
          </Markdown>
        </GridChild>
      </Grid>
    </StyledTextBlock>
  );
};
