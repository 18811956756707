import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const StyledFeatures = styled.div`
  .FeatureContent {
    margin: 0 ${spacing.small} ${spacing.medium} ${spacing.small};
  }

  @media screen and (min-width: ${breakpoints.desktopSmall}) {
    margin: 0;
  }

  .feature {
    display: flex;
    align-items: center;
    padding-top: ${spacing.small};

    img {
      margin-right: ${spacing.small};
      width: 24px;
      height: 24px;
    }
  }
`;
