import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const PageNotFoundView = styled.div`
  margin-bottom: ${spacing.XL};
  .ProductWrapper {
    padding: 0 ${spacing.small};
    padding-top: ${spacing.medium};
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: ${spacing.medium};
    }
  }
`;
