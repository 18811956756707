import styled from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';

export const ProductImageDialogStyled = styled.aside`
  .DialogImage {
    width: 100%;
    margin-bottom: ${spacing.regular};

    &.Show {
      display: block;
    }

    &.Hide {
      display: none;
    }

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  .DialogImage:first-of-type {
    margin-top: ${spacing.regular};
  }
`;
