import { createGlobalStyle, css } from 'styled-components';

import { fontWeights } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

const cssVariables = css`
  :root {
    --header-height: 56px;
    --map-offset: 0;
    --map-width: 100%;
    --map-height: 500px;
    --map-height-desktop: 600px;
  }
`;

const globalStyle = css`
  html {
    font-size: 16px;
    min-height: 100vh;
    color: ${colors.black};
  }

  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${fontFamilies.fontRegular}, sans-serif;
    font-weight: ${fontWeights.fontRegular};
    padding: ${spacing.menuHeight} 0 0;
  }

  #root {
    min-height: 100vh;
  }

  img {
    max-width: 100%;
  }

  .no-js img.lazyload {
    display: none;
  }

  .map {
    position: relative;
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h6,
  p {
    padding: 0;
    margin: 0;
  }

  button {
    font-family: 'Helvetica Neue', sans-serif;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${cssVariables};
  ${globalStyle};
`;
