import { ImageFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { HTag } from '../HTag/HTag';
import { LinkBlock } from '../LinkBlock/LinkBlock';

import { BigPlugStyled, GridChild, Image } from './BigPlug.styled';

interface BigPlugProps {
  title?: string | null;
  image?: ImageFragment;
  CTAText?: string | null;
  CTAUrl?: string | null;
  className?: string;
  style?: React.CSSProperties;
}

export const BigPlug: React.FC<BigPlugProps> = ({
  className,
  style,
  title,
  image,
  CTAText,
  CTAUrl,
}) => {
  return (
    <BigPlugStyled className={className} style={style}>
      <Grid columnGap={0} rowGap={0} autoFlow="dense">
        <GridChild
          $greyBackground
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12, start: 1 },
            { breakpoint: 'desktop', columns: 6, start: 7 },
          ]}
        >
          <Image
            src={image?.url || ''}
            alt={image?.alt || image?.title || 'image'}
          />
        </GridChild>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12, start: 1 },
            { breakpoint: 'desktop', columns: 6, start: 1 },
          ]}
        >
          <div className="ContentWrapper">
            <HTag
              className="ContentTitle"
              type="h2"
              styleType={[
                { styleType: 'header3' },
                { breakpoint: 'desktop', styleType: 'header1' },
              ]}
            >
              {title}
            </HTag>
            <LinkBlock square linkText={CTAText} linkUrl={CTAUrl} />
          </div>
        </GridChild>
      </Grid>
    </BigPlugStyled>
  );
};
