import { wordifySlug } from './stringHandling';

export const constructBackLink = (route: string, currentSlug: string) => {
  return {
    url: `/${route}/${currentSlug}`,
    title: wordifySlug(currentSlug),
  };
};

// We had a problem with mixed links sometimes starting with / and sometimes not
export const fixSlash = (url: string) => {
  if (url.startsWith('/')) {
    return url;
  }
  return `/${url}`;
};
