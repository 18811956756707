import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

interface BulletListStyledProps {
  $isBig?: boolean;
}

export const StyledBulletList = styled.div<BulletListStyledProps>`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.medium} 0;
  }

  .content {
    h2 {
      padding-bottom: ${spacing.small};
    }

    ul {
      margin: 0;
      padding: 0 17px;
      list-style: none;

      li {
        color: ${colors.black};
        font-size: ${({ $isBig }) =>
          $isBig ? fontSizes.header4 : fontSizes.body16};
        line-height: ${({ $isBig }) =>
          $isBig ? lineHeights.header4 : lineHeights.body16};
        font-family: ${fontFamilies.fontRegular};
        font-weight: ${fontWeights.fontMedium};
      }

      li::before {
        content: '\2022';
        color: ${colors.black};
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
`;
