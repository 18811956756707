import { useState } from 'react';

import { spacing } from '@hultafors/toeguard/helpers';

import { DownloadFileBox } from '../DownloadFileBox/DownloadFileBox';
import { Paragraph } from '../Paragraph/Paragraph';
import { Tabs } from '../Tabs/Tabs';

import { ProductInformationTabsStyled } from './ProductInformationTabs.styled';

export { ProductInformationTabsStyled } from './ProductInformationTabs.styled';

interface ProductInformationTabsProps {
  overviewLabel?: string;
  intro?: string;
  documentsLabel?: string;
  documents?: any[];
  detailsLabel?: string;
  detailsInfo?: string;
  product?: any;
  products?: any;
}

export const ProductInformationTabs: React.FC<ProductInformationTabsProps> = ({
  overviewLabel,
  intro,
  documentsLabel,
  documents,
  detailsLabel,
  detailsInfo,
  product,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const detailsArray = detailsInfo?.split(/\r|\n/).filter(Boolean);

  const tabs: string[] = [];
  if (intro) {
    tabs.push(overviewLabel ?? 'Overview');
  }
  if (documents?.length) {
    tabs.push(documentsLabel ?? 'Documents');
  }
  if (detailsArray?.length) {
    tabs.push(detailsLabel ?? 'Details');
  }

  return (
    <ProductInformationTabsStyled>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}>
        {intro && activeTab === 0 && (
          <>
            <Paragraph styleType="body16" className="OverviewText">
              {intro}
            </Paragraph>
            {product.isEsd && (
              <img
                style={{
                  width: spacing.medium,
                  height: 'auto',
                  marginTop: spacing.small,
                }}
                src="/assets/gfx/ESD_logotype.svg"
                alt="esd approved"
              />
            )}
          </>
        )}
        {!!documents?.length
        && activeTab === 1
        && documents?.map((document, i) => {
          return (
            <DownloadFileBox
              key={`document${i}`}
              url={document.url}
              label={document.name}
              size={document.size}
              filetype={document.filetype}
            />
          );
        })}
        {!!detailsArray?.length && activeTab === 2 && (
          <ul className="DetailInfo">
            {detailsArray.map((item, index) => (
              <li key={`Detail-Key-${index}`}>{item}</li>
            ))}
          </ul>
        )}
      </Tabs>
    </ProductInformationTabsStyled>
  );
};
