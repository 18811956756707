import styled, { keyframes } from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';

const appear = keyframes`
from{transform: translate(50px) }
to{transform: translate(-16px) }
`;
const disappear = keyframes`
  from{transform: translate(-16px) }
to{transform: translate(50px) }
  `;

interface SearchInputProps {
  $full?: boolean;
}

export const SearchInputStyled = styled.div<SearchInputProps>`
  position: relative;
  max-width: ${({ $full }) => ($full ? null : '343px')};
  margin: 0 auto;

  form {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.gray3};
    align-items: center;
    height: 56px;
    border-radius: 100px;

    &.focus {
      background-color: ${colors.gray5};
    }

    > div {
      margin-bottom: 0;
    }

    input {
      padding-left: 8px;
      border-bottom: none;
      font-size: ${fontSizes.s};
      line-height: ${lineHeights.s};
    }
  }

  .IconButton {
    height: ${spacing.regular};
    width: ${spacing.regular};
    padding: 0;

    &.close {
      min-width: ${spacing.small};
      min-height: ${spacing.small};
      display: initial;
      position: relative;
      z-index: 2;
      animation: ${disappear} 0.2s forwards;

      &.animate {
        animation: ${appear} 0.2s forwards;
      }
    }

    &.search {
      min-width: ${spacing.regular};
      min-height: ${spacing.regular};
      margin-left: ${spacing.small};
    }
  }

  .ResultText {
    margin-bottom: ${spacing.medium};
    margin-top: ${spacing.medium};
    text-align: center;
    font-size: ${fontSizes.body16};
    font-weight: ${fontWeights.fontRegular};
  }

  &.SearchView {
    .ResultText {
      margin-bottom: 0;
    }

    p {
      font-size: ${fontSizes.header4};
      line-height: ${lineHeights.header4};
      font-weight: ${fontWeights.fontMedium};
    }
  }
`;
