import { FieldWrapper } from '../FieldWrapper/FieldWrapper';
import { Label } from '../Label/Label';

import { StyledTextarea } from './Textarea.styled';

interface TextareaProps {
  id: string;
  inline?: boolean;
  label: string;
  wrapperProps?: object;
}

export const Textarea: React.FC<TextareaProps> = ({
  label,
  id,
  inline,
  wrapperProps,
}) => {
  return (
    <FieldWrapper inline={inline} {...wrapperProps}>
      <Label htmlFor={id}>{label}</Label>
      <StyledTextarea id={id} />
    </FieldWrapper>
  );
};
