import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const ContactInformationStyled = styled.div`
  padding: 0 ${spacing.small};
  margin-top: ${spacing.medium};
  margin-bottom: ${spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;

export const Block = styled.div`
  margin-bottom: ${spacing.regular};

  &:first-of-type {
    margin-bottom: ${spacing.medium};
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Title = styled(Paragraph).attrs({
  styleType: 'body16Medium',
})`
  margin-bottom: ${spacing.xsmall};
`;
export const Row = styled(Paragraph).attrs({ styleType: 'body16' })`
  text-decoration: none;
  font-style: normal;
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body16};
  margin: 0;
  color: ${colors.black};

  a {
    text-decoration: none;
    font-style: normal;
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    margin: 0;
    color: ${colors.black};

    &:hover {
      color: ${colors.blackHover};
    }
  }
`;
