import dynamic from 'next/dynamic';

import { Store } from '@hultafors/shared/types';

import { FindRetailersPageFragment } from '@hultafors/toeguard/types';

import { StoreItem } from '../StoreItem/StoreItem';

import { FindRetailersListStyled } from './FindRetailersList.styled';

const Accordion = dynamic(() =>
  import('@hultafors/shared/components').then((module) => module.Accordion),
);

interface FindRetailersListProps {
  stores: Store[];
  pageContent: FindRetailersPageFragment;
  selectItem?(...args: any[]): void;
  selectedItem?: Store;
}
export const FindRetailersList: React.FC<FindRetailersListProps> = ({
  stores = [],
  // selectedItem,
  pageContent,
  // selectItem,
}) => {
  const keyPrefix = 'Store';

  function storeMapper({ id, mapLink, storeSells, data: item }: Store) {
    const key = `FindRetailersList-${id}`;

    return (
      <StoreItem
        key={key}
        coordinates={{ lng: item.longitude ?? '', lat: item.latitude ?? '' }}
        label={item.companyName}
        subLabel={item.city}
        keyPrefix={keyPrefix}
        id={key}
        address={item.address1}
        zipCode={item.zipCode}
        city={item.city}
        country={item.country}
        mapLink={mapLink}
        view_on_map={pageContent.viewOnMap}
        emailAddress={item.emailAddress}
        phone={item.phone}
        storeSells={storeSells}
        this_store_also_sells={pageContent.thisStoreAlsoSellsLabel}
        website={item.webSite}
        visit_web_site={pageContent.visitWebsiteLabel}
        openingHours={item.openingHours}
      />
    );
  }
  if (!stores?.length) {
    return null;
  }
  return (
    <FindRetailersListStyled>
      <Accordion>
        {stores.map(storeMapper)}
        <span />
      </Accordion>
    </FindRetailersListStyled>
  );
};
