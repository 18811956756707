import styled, { css, CSSProperties, RuleSet } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';

export const STATUSES = {
  error: 'error',
  info: 'info',
  success: 'success',
  warning: 'warning',
} as const;
export type AlertStatus = keyof typeof STATUSES;

export const SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;
export type AlertSize = keyof typeof SIZES;

export type AlertPlacement = 'top' | 'bottom';

interface StyledAlertProps {
  $status: AlertStatus;
  $size: AlertSize;
  $position: CSSProperties['position'];
  $placement: AlertPlacement;
}

const statusStyles = new Map<AlertStatus, RuleSet>([
  [
    'info',
    css`
      flex-direction: column;
      align-items: flex-start;
      background: ${colors.white};
      color: ${colors.black};
      border: 1px solid ${colors.gray4};

      p {
        padding-bottom: ${spacing.small};
      }

      margin: ${spacing.small};
      width: calc(100% - 32px);

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        margin: ${spacing.regular};
        max-width: 330px;
      }
    `,
  ],
  [
    'error',
    css`
      background: ${colors.hellNo};
      color: ${colors.white};

      button {
        background: ${colors.white};
        color: ${colors.black};
      }
    `,
  ],
  [
    'warning',
    css`
      background: ${colors.yellow};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
  ],
  [
    'success',
    css`
      background: ${colors.hellYeah};
      color: ${colors.black};

      button {
        background: ${colors.black};
        color: ${colors.white};
      }
    `,
  ],
]);

const getStatusStyle = ({ $status }: StyledAlertProps) =>
  $status && statusStyles.get($status);

const getSizeStyle = ({ $size }: StyledAlertProps) =>
  ({
    small: css({
      padding: spacing.xsmall,
    }),
    medium: css({
      padding: spacing.small,
    }),
    large: css({
      padding: spacing.medium,
    }),
  }[$size in SIZES ? $size : 'medium']);

const getPositionStyle = ({
  $position: position,
  $placement: placement,
}: StyledAlertProps) => {
  if (position !== 'absolute' && position !== 'fixed') {
    return css``;
  }
  return css`
    position: ${position};
    ${{
      absolute: css`
        inset: ${placement === 'top' ? 0 : 'auto'} 0
          ${placement === 'bottom' ? 0 : 'auto'} 0;
        width: 100%;
        margin: 0 auto;
      `,
      fixed: css`
        inset: ${placement === 'top' ? 0 : 'auto'} 0
          ${placement === 'bottom' ? 0 : 'auto'} 0;
        width: 100%;
        margin: 0 auto;
      `,
    }[position]};
  `;
};

export const StyledAlert = styled.div<StyledAlertProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing.small} auto;
  z-index: 3;

  button {
    appearance: none;
    padding: ${spacing.tiny} ${spacing.small};
    border: none;
    font-size: ${fontSizes.body14};
    min-height: 0;
  }

  ${getPositionStyle};
  ${getStatusStyle};
  ${getSizeStyle};
  .CookieConsentText {
    font-size: ${fontSizes.body12};
    line-height: ${lineHeights.body12};
    font-weight: ${fontWeights.fontRegular};
    font-family: ${fontFamilies.fontRegular};

    a {
      font-size: ${fontSizes.body12};
      line-height: ${lineHeights.body12};
      font-weight: ${fontWeights.fontRegular};
      font-family: ${fontFamilies.fontRegular};
      text-decoration: none;
      text-decoration: underline;
      color: ${colors.black};
    }
  }

  .closeButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }
  }
`;
