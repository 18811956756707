import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';

export const BackLinkStyled = styled.div`
  padding-top: ${spacing.small};
  padding-left: ${spacing.small};
  padding-right: ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-top: ${spacing.small};
    padding-left: ${spacing.medium};
    padding-right: ${spacing.medium};
  }

  &.ProductDetail {
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-top: ${spacing.small};
      padding-left: ${spacing.small};
      padding-right: ${spacing.small};
    }

    @media screen and (min-width: ${breakpoints.desktopSmall}) {
      padding-top: ${spacing.small};
      padding-left: ${spacing.medium};
      padding-right: ${spacing.medium};
    }
  }

  &.InformationPage {
    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-top: ${spacing.small};
      padding-left: ${spacing.small};
      padding-right: ${spacing.small};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      padding-top: ${spacing.small};
      padding-left: ${spacing.medium};
      padding-right: ${spacing.medium};
    }
  }

  font-size: ${fontSizes.body12};
  color: ${colors.gray1};

  a {
    color: ${colors.hellBlue};
    font-size: ${fontSizes.body12};
  }
`;
