export const colors = {
  hellBlue: '#0000FF',
  hellBlueHover: '#5F5FFF',
  hellSalmon: '#FA826E',
  // hellSalmonHover: '#EA7966',
  // hellSalmonLight: '#FCC0B7',
  hellTurqouise: '#00FFC8',
  hellTurqouiseHover: '#07E2B3',
  // hellTurqouiseLight: '#80FFE3',
  gray1: '#949494',
  gray2: '#C0C0C0',
  gray3: '#DEDEDE',
  gray4: '#EAEAEA',
  gray5: '#F7F7F7',
  gray6: '#FAFAFA',
  black: '#000000',
  blackHover: '#2D2D2D',
  white: '#FFFFFF',
  hellNo: '#FF1E1E',
  hellYeah: '#67FC6D',
  hellActive: '#5686FF',
  toeNo: '#FF1E1E',
  toeYes: '#67FC6D',
  toeLime: '#DCFF00',
  toeLimeHover: '#D2F205',
  yellow: '#FFD700',
} as const;
