import Link from 'next/link';
import styled from 'styled-components';

import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

export const Label = styled(Paragraph).attrs({ styleType: 'body16Medium' })`
  margin: 0;
`;

export const Inner = styled.div`
  padding: ${spacing.small};
  background-color: ${colors.gray5};
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: ${spacing.regular};
  margin-block-end: ${spacing.regular};
`;

export const Address = styled(Paragraph).attrs({ styleType: 'body16' })`
  /* keep */
`;

export const OpeningHours = styled(Paragraph).attrs({ styleType: 'body16' })`
  /* keep */
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
`;

export const EmailAndPhoneWrapper = styled(Contact)`
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing.tiny};
`;

export const ContactLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const StoreSells = styled(Contact)`
  align-items: center;
  padding: ${spacing.small} 0;

  a {
    color: ${colors.black};
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    text-decoration: underline;
  }
`;

export const Stores = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Separator = styled.div`
  display: inline-flex;
  margin: 0 5px;
`;

export const StoresWrapper = styled.div`
  flex-direction: column;
`;

export const StoreText = styled.span`
  color: ${colors.black};
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body16};
`;
