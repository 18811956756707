import styled from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';

export const FavoriteStyled = styled.span`
  display: inline-block;
  cursor: pointer;
  z-index: 2;

  svg.Favorite path {
    stroke: ${colors.black};
    fill: transparent;
    transition: all 0.2s;
  }

  svg.Remove {
    width: 16px;
    height: 16px;
    transition: all 0.2s;

    circle {
      stroke: ${colors.hellBlue};
      transition: all 0.2s;
    }

    path {
      stroke: ${colors.hellBlue};
      transition: all 0.2s;
    }
  }

  &:hover {
    svg path {
      stroke: ${colors.gray1};
    }

    svg.Remove {
      circle {
        stroke: ${colors.blackHover};
        transition: all 0.2s;
      }

      path {
        stroke: ${colors.blackHover};
        transition: all 0.2s;
      }
    }
  }

  &.Active {
    svg.Favorite path {
      fill: ${colors.toeLime};
    }
  }
`;
