import { UrlObject } from 'url';

import Link from 'next/link';

import { Icon } from './Icon';
import { TextButtonStyled, TextButtonVariant } from './TextButton.styled';

export interface TextButtonProps {
  arrow?: boolean;
  loading?: boolean;
  variant?: TextButtonVariant;
  clean?: boolean;
  small?: boolean;
  icon?: string | React.ReactNode;
  iconUrl?: string;
  iconLeft?: boolean;
  iconSize?: number;
  to?: string | UrlObject;
  onClick?: React.MouseEventHandler;
  children?: React.ReactNode;
  className?: string;
}

export const TextButton: React.FC<TextButtonProps> = ({
  arrow,
  loading,
  variant = 'black',
  to,
  icon,
  iconSize = 24,
  onClick,
  clean,
  small,
  iconUrl,
  iconLeft,
  children,
  className,
}) => {
  if (!to && !onClick) {
    return null;
  }
  if (!to) {
    return (
      <TextButtonStyled
        className={className}
        onClick={onClick}
        $variant={variant}
        $isLoading={loading}
        $icon={!!icon}
        $iconSize={iconSize}
        $arrow={!icon && arrow} // Can't have both arrow and icon
        $clean={clean}
        $small={small}
      >
        {children}
        {icon && !arrow && <Icon icon={icon} />}
        {arrow && !icon && <Icon icon="/assets/gfx/arrow-right.svg" />}
      </TextButtonStyled>
    );
  }
  const rel
    = (typeof to === 'string' && to.includes('http'))
    || (typeof to !== 'string' && to.protocol)
      ? 'noreferrer noopener'
      : '';
  const target
    = (typeof to === 'string' && to.includes('http'))
    || (typeof to !== 'string' && to.protocol)
      ? '_blank'
      : '';
  return (
    <TextButtonStyled
      // remove eslint, since extracting props to NOT send them is arguably still valid js
      // eslint-disable-next-line no-unused-vars
      as={Link}
      className={className}
      onClick={onClick}
      href={to}
      target={target}
      rel={rel}
      $variant={variant}
      $isLoading={loading}
      $icon={!!icon}
      $iconSize={iconSize}
      $arrow={!icon && arrow} // Can't have both arrow and icon
      $clean={clean}
      $small={small}
    >
      {children}
      {icon && !arrow && (
        <Icon
          icon={icon}
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        />
      )}
      {arrow && !icon && (
        <Icon
          icon="/assets/gfx/arrow-right.svg"
          style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
        />
      )}
    </TextButtonStyled>
  );
};
