import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';

export const StyledTextBlock = styled.div`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.medium} 0;
  }

  h2 {
    padding-bottom: ${spacing.regular};
  }

  h3 {
    padding-bottom: ${spacing.small};
  }

  .markdown-wrapper {
    p:not(:last-child) {
      margin-bottom: ${spacing.regular};
    }

    a {
      text-decoration: none;
      text-decoration: underline;
      color: ${colors.black};
    }
  }
`;
