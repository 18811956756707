import { breakpoints } from '@hultafors/shared/constants';

import { ImageFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { Image } from '../Image/Image';
import { Markdown } from '../Markdown/Markdown';

import { StyledInformationHero } from './InformationHero.styled';

interface DescriptionRendererProps {
  children: React.ReactNode;
}
const DescriptionRenderer: React.FC<DescriptionRendererProps> = ({
  children,
  ...rest
}) => (
  <HTag type="h2" styleType="header5" {...rest}>
    {children}
  </HTag>
);
interface InformationHeroProps {
  title?: string | null;
  description?: string | null;
  image?: ImageFragment | null;
  mobileImage?: ImageFragment | null;
  className?: string;
  center?: boolean;
}
export const InformationHero: React.FC<InformationHeroProps> = ({
  title,
  image,
  description,
  className,
  center,
  mobileImage,
}) => {
  if (!title && !description && !image) {
    return null;
  }
  return (
    <StyledInformationHero
      className={className}
      $center={center}
      $image={!!image}
      $mobileImage={!!mobileImage}
    >
      <Grid
        columnGap={center ? 0 : 48}
        maxWidth={center ? breakpoints.maxPageWidth : '3000px'}
      >
        <GridChild
          columnSpan={[
            { columns: 4 },
            {
              breakpoint: 'mobileMax',
              columns: center ? 8 : 12,
              start: center ? 3 : 1,
            },
            {
              breakpoint: 'desktop',
              columns: center ? 6 : 5,
              start: center ? 4 : 1,
            },
            {
              breakpoint: 'desktopXL',
              columns: center ? 6 : 4,
              start: center ? 4 : 1,
            },
          ]}
        >
          <div className="HeroText">
            {title && (
              <HTag
                type="h1"
                styleType={[
                  { styleType: 'header3' },
                  { breakpoint: 'mobileMax', styleType: 'header1' },
                ]}
              >
                {title}
              </HTag>
            )}
            {description && (
              <Markdown options={{ overrides: { span: DescriptionRenderer } }}>
                {description}
              </Markdown>
            )}
          </div>
        </GridChild>

        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 12, start: 1 },
            { breakpoint: 'desktop', columns: 6, start: 7 },
          ]}
        >
          {image && !center && (
            <Image
              className="DesktopImage"
              src={`${image.url}?w=1200`}
              alt={image.alt || 'image'}
            />
          )}
          {mobileImage && !center && (
            <Image
              className="MobileImage"
              src={`${mobileImage.url}?w=800`}
              alt={mobileImage.alt || 'image'}
            />
          )}
        </GridChild>
      </Grid>
    </StyledInformationHero>
  );
};
