import { LocalStorage } from './local-storage';

const KEY = 'tg_cart';

/**
 * @desc Get cart from local storage
 * @fires Function accesses local storage
 * @returns {array} cart or [] if not existing
 */
export const getCart = (): any[] => {
  return LocalStorage?.get(KEY) ?? [];
};

/**
 * @desc Adds a product to cart. Product is transformed to only needed properties.
 * @fires Function accesses local storage
 * @param {array} cart - Array of products from local storage
 * @param {object} product - product representation from detail page
 * @param {number} amount - Number of products to add
 * @return {array} cart
 */
export const addItemToCart = ({
  cart = [],
  product,
  amount = 1,
}: {
  cart: any[];
  product: any;
  amount?: number;
}) => {
  const result = addToCartHelper({ cart, product, amount });
  LocalStorage?.set(KEY, cart);
  return result;
};

/**
 * @desc Deletes item from cart, if deleting last item, cart is removed from local storage
 * @fires Function accesses local storage
 * @param {array} cart - Array of products from local storage
 * @param object cartItem - item representing product cart array
 * @return cart (array)
 */
export const deleteItemFromCart = ({
  cart,
  cartItem,
}: {
  cart: any[];
  cartItem: any;
}) => {
  const result = deleteItemFromCartHelper({ cart, cartItem });
  LocalStorage?.set(KEY, cart);
  return result;
};

/**
 * @desc Removes cart from local storage
 * @fires Function accesses local storage
 * @return void
 */
export const removeCart = () => {
  LocalStorage?.remove(KEY);
};

/**
 * @private Public for tests only
 * @desc Adds a product to cart. Product is transformed to only needed properties.
 * @param {array} cart - Array of products from local storage
 * @param {object} product - product representation from detail page
 * @param {number} amount - Number of products to add
 * @return {array} cart
 */
export const addToCartHelper = ({
  cart,
  product,
  amount = 1,
}: {
  cart: any[];
  product: any;
  amount?: number;
}) => {
  let result: any[] = [];

  if (!product?.productId) {
    return cart ? [...cart] : result;
  }

  product.amount = amount;

  if (cart) {
    const existingItem = cart.find((x) => x.productId === product.productId);

    if (existingItem) {
      result = [...cart];
    } else {
      result = [...cart, product];
    }
  } else {
    // Cart does not exist, create with new product
    result = [product];
  }

  return result;
};

/**
 * @private Public for tests only
 * @desc Deletes item from cart, if deleting last item, cart is removed from local storage
 * @param {array} cart - Array of products from local storage
 * @param object cartItem - item representing product cart array
 * @return cart (array)
 */
export const deleteItemFromCartHelper = ({
  cart,
  cartItem,
}: {
  cart: any[];
  cartItem: any;
}) => {
  let result: any[] = [];
  if (!cartItem?.productId) {
    return cart ? [...cart] : result;
  }
  if (cart) {
    result = cart.filter((item) => item.productId !== cartItem.productId);
  }
  return result;
};
