import React, { useState } from 'react';

import Slider from 'react-slick';

import { ImageFragment } from '@hultafors/toeguard/types';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { Paragraph } from '../Paragraph/Paragraph';

import { ImageSliderStyled } from './ImageSlider.styled';

interface ImageSliderProps {
  images: ImageFragment[];
}
export const ImageSlider: React.FC<ImageSliderProps> = ({ images = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const changedIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const SETTINGS = {
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    lazyLoad: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow className="Next" />,
    prevArrow: <Arrow className="Prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          arrows: true,
        },
      },
    ],
    afterChange: changedIndex,
  };
  return (
    <ImageSliderStyled>
      <Grid rowGap={16} columnGap={48}>
        <GridChild
          className="GridChildSliderWrapper"
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <Slider {...SETTINGS} className="article">
            {images?.map((item, index) => (
              <div key={`Carousel-${index}`} className="ImgWrapper">
                <img
                  src={`${item.url}?w=1200`}
                  alt={(item.alt || item.title) ?? ''}
                  title={item.title ?? ''}
                />
              </div>
            ))}
          </Slider>

          <div className="Counter">
            <Paragraph styleType="body16">
              {`${currentIndex ? currentIndex + 1 : 1} / ${images.length ?? 0}`}
            </Paragraph>
          </div>
        </GridChild>
      </Grid>
    </ImageSliderStyled>
  );
};

interface ArrowProps {
  className: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Arrow: React.FC<ArrowProps> = ({ className, onClick }) => {
  return <button className={className} onClick={onClick} id={className} />;
};
