import styled, { css } from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';

import { TextButton } from '../TextButton/TextButton';

export const TabsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: ${spacing.regular};

  &:empty {
    display: none;
  }
`;

export const Controls = styled.div<{ $center?: boolean }>`
  display: flex;
  text-align: center;
  gap: ${spacing.small};

  &:empty {
    display: none;
  }
  ${({ $center }) =>
    $center
    && css`
      justify-content: center;
    `}
`;

export const Content = styled.div`
  &:empty {
    display: none;
  }
`;

export const TabButton = styled(TextButton).attrs({
  clean: true,
  small: true,
})<{ $active?: boolean }>`
  text-decoration: none;

  ${({ $active }) =>
    $active
    && css`
      border-bottom: thin solid ${colors.black};
    `}
`;
