import Link from 'next/link';
import styled from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';

export const MenuStyled = styled.div`
  .SlideInBody {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    padding-bottom: ${spacing.regular};
  }
`;

export const Flex = styled.div<{ $pt?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ $pt }) => $pt && spacing.regular};
`;

export const MenuItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: ${fontSizes.header5};
  font-weight: ${fontWeights.fontBold};
  margin-bottom: ${spacing.small};
  cursor: pointer;
`;

export const Chevron = styled.img<{ $open?: boolean }>`
  margin-left: auto;
  object-fit: cover;
  transform: ${({ $open }) => $open && 'rotate(180deg)'};
`;

export const PopMenu = styled.div<{ $open?: boolean; $items?: number }>`
  width: 100%;
  transition: 0.2s;
  height: ${({ $items = 0, $open }) =>
    $open
      ? `calc(((24px + ${spacing.xsmall}) * ${$items}) + ${spacing.xsmall})`
      : '0px'};
  overflow: hidden;
`;

export const PaddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.small} ${spacing.small};
`;

/* eslint-disable-next-line no-undef */
export const SubMenuItem = styled(Link)`
  font-size: ${fontSizes.body14};
  font-weight: ${fontWeights.fontMedium};
  height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: ${spacing.xsmall};
  }
`;

export const PartnerLink = styled(Link)`
  margin-top: auto;
  margin-left: auto;

  img {
    margin-left: ${spacing.xsmall};
  }
`;
