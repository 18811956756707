import styled from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';

export const NavItemStyled = styled.div`
  position: relative;

  .Favorite,
  .Cart {
    position: relative;
    width: 24px;
    height: 24px;
    line-height: 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .Favorite {
    &.HaveFavorites {
      svg {
        path {
          fill: ${colors.black};
        }
      }
    }
  }

  .Cart .Badge {
    top: -7px;
    right: -8px;
  }
`;
