import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

import { StyledBlueInfoBox } from './BlueInfoBox.styled';
interface BlueInfoBoxProps {
  body?: string | null;
}
export const BlueInfoBox: React.FC<BlueInfoBoxProps> = ({ body }) => {
  return (
    <StyledBlueInfoBox>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <div className="content">
            <HTag
              type="h2"
              styleType={[
                { styleType: 'header3' },
                { breakpoint: 'mobileMax', styleType: 'header1' },
              ]}
            >
              {body}
            </HTag>
          </div>
        </GridChild>
      </Grid>
    </StyledBlueInfoBox>
  );
};
