export const fontSizes = {
  mega: '100px',
  header1: '60px',
  header2: '40px',
  header3: '30px',
  header4: '24px',
  header5: '20px',
  body16: '16px',
  body14: '14px',
  body12: '12px',
  body10: '10px',

  // old should be replaced
  microcopy: '0.625rem',
  xs: '0.75rem',
  s: '0.75rem',
  m: '1rem',
  l: '1.25rem',
  body: '1.25rem',
  h4: '1.25rem',
  h3: '2rem',
  h2: '2.5rem',
  h1: '3rem',
} as const;
