import { Content, Controls, TabButton, TabsStyled } from './Tabs.styled';

interface TabsProps {
  className?: string;
  style?: React.CSSProperties;
  activeTab: number;
  setActiveTab: (tab: number) => void;
  tabs?: string[];
  children?: React.ReactNode;
  center?: boolean;
}

export const Tabs: React.FC<TabsProps> = ({
  className,
  style,
  activeTab,
  setActiveTab,
  tabs = [],
  children,
  center,
}) => {
  const tabsMapper = (label: string, index: number) => {
    const onClick = () => setActiveTab(index);
    return (
      <TabButton
        key={`Tab-${label}`}
        $active={activeTab === index}
        onClick={onClick}
      >
        {label}
      </TabButton>
    );
  };
  return (
    <TabsStyled className={className} style={style}>
      <Controls $center={center}>{tabs.map(tabsMapper)}</Controls>
      <Content>{children}</Content>
    </TabsStyled>
  );
};
