import { ColorPlugFragment } from '@hultafors/toeguard/types';

import { ArrowLink } from '../ArrowLink/ArrowLink';
import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';
import { RouterLink } from '../RouterLink/RouterLink';

import { ColorPlugsStyled } from './ColorPlugs.styled';

interface ColorPlugsProps {
  plugs?: ColorPlugFragment[];
}
export const ColorPlugs: React.FC<ColorPlugsProps> = ({ plugs = [] }) => {
  if (!plugs?.length) {
    return null;
  }

  const getColorByIndex = (index: number) => {
    switch (index) {
      case 1:
        return 'black';
      default:
        return 'white';
    }
  };

  const getGridStartPosition = (index: number) => {
    if (index === 0) {
      return 1;
    }
    if (index === 1) {
      return 7;
    }
    if (index >= 2) {
      return 1;
    }
  };
  const getGridStartPositionDesktopLarge = (index: number) => {
    if (index === 0) {
      return 1;
    }
    if (index === 1) {
      return 8;
    }
    if (index >= 2) {
      return 1;
    }
  };

  const plugMapper = (item: ColorPlugFragment, index: number) => {
    return (
      <GridChild
        key={`ColorPlug-${item.id}`}
        className={`Box Box-${index + 1}`}
        columnSpan={[
          { columns: 4 },
          {
            breakpoint: 'mobileMax',
            columns: index >= 2 ? 12 : 6,
            start: getGridStartPosition(index),
          },
          {
            breakpoint: 'desktopLarge',
            columns: index >= 2 ? 12 : 5,
            start: getGridStartPositionDesktopLarge(index),
          },
        ]}
      >
        <RouterLink className="ContentWrapper" to={item.url}>
          <HTag
            type="h3"
            styleType={[
              { styleType: 'header2' },
              {
                breakpoint: 'mobileMax',
                styleType: index >= 2 ? 'header1' : 'header2',
              },
            ]}
          >
            {item.title}
          </HTag>
          <ArrowLink
            large
            className="CTA"
            title={item.ctaLinkText}
            color={getColorByIndex(index)}
          />
        </RouterLink>
      </GridChild>
    );
  };
  return (
    <ColorPlugsStyled>
      <Grid
        columnGap={48}
        rowGap={[
          { rowGap: 24 },
          { breakpoint: 'mobileMax', rowGap: 48 },
          { breakpoint: 'desktopLarge', rowGap: 96 },
        ]}
      >
        {plugs?.map(plugMapper)}
      </Grid>
    </ColorPlugsStyled>
  );
};
