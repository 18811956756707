import slugify from 'slugify';

import { routes, ToeguardRoute } from './routes';

export const createDetailPageUrl = ({
  name,
  category,
  productId,
  sku,
}: any) => {
  const productSlug = slugify(name);
  // category = category ? category.trim() : '';
  category = category ? category.trim() : 'safety-shoes';

  if (category) {
    return `/${routes.PRODUCTS}/${category}/${sku}-${productSlug}/${productId}`;
  }

  return `/${routes.PRODUCTS}/${sku}-${productSlug}/${productId}`;
};

export const constructSubMenuUrl = (item: any) => {
  if (item) {
    // type of model
    const typeName = item?.pageLink?.__typename;

    switch (typeName) {
      case 'CategoryPageRecord':
        return createLink('PRODUCTS', item);
      case 'InformationPageRecord':
        return createLink('GUIDES', item);
      default:
        return item.url;
    }
  }
  return '';
};

const createLink = (route: ToeguardRoute, item: any) => {
  const slug = item.pageLink ? item.pageLink.slug : item.url;
  return `/${routes[route]}/${slug}`;
};
