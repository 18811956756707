export const lineHeights = {
  mega: '110px',
  header1: '68px',
  header2: '50px',
  header3: '40px',
  header4: '26px',
  header5: '22px',
  body16: '24px',
  body14: '18px',
  body12: '18px',
  body10: '16px',

  // old and should be replaced
  xs: 1,
  bodyXS: 1,
  body: 1.25,
  s: 1.25,
  m: 1.5,
  l: 1.7,
} as const;
