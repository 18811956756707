import { StyledImage } from './Image.styled';
interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  caption?: string;
  srcset?: string;
}
export const Image: React.FC<ImageProps> = ({
  caption,
  srcset,
  sizes = 'auto',
  ...rest
}) => {
  return <StyledImage srcSet={srcset} sizes={sizes} {...rest} />;
};
