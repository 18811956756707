import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';

export const StyledQuote = styled.div`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.large} 0;
  }

  .AuthorWrapper {
    display: flex;
    padding-top: ${spacing.xsmall};
  }

  .Author {
    padding-right: ${spacing.xsmall};
  }

  .Profession {
    color: ${colors.hellSalmon};
  }
`;
