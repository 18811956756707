import { StyledGraphic, StyledGraphicWrapper } from './Graphic.styled';
interface GraphicProps {
  src?: string | null;
  alt?: string | null;
  width?: string;
}
export const Graphic: React.FC<GraphicProps> = ({
  width = '40px',
  src,
  alt,
}) => {
  if (!src) {
    return null;
  }
  return (
    <StyledGraphicWrapper $width={width}>
      <StyledGraphic src={src} alt={alt ?? ''} />
    </StyledGraphicWrapper>
  );
};
