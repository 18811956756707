import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';

export const StyledVideo = styled.div`
  display: block;

  .video-wrapper {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  video {
    align-self: center;
    max-width: 100%;
    max-height: calc(100vh - 56px);
    object-fit: cover;
    width: 100%;
  }

  .controls {
    button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 56px;
      height: 56px;
      padding: 0;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s ease;
      cursor: pointer;
      background: rgb(234 234 234 / 50%);
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid ${colors.hellTurqouise};

      &.visible {
        opacity: 1;
        pointer-events: initial;
        transition: opacity 0.3s ease;
      }

      svg path {
        opacity: 1;
        fill: ${colors.hellTurqouise};
      }

      &::before {
        @media screen and (min-width: ${breakpoints.desktop}) {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background: ${colors.blackHover};
          transform: translateX(-100%);
          pointer-events: none;
          z-index: -1;
        }
      }

      &:hover::before {
        transform: translateX(0);
      }

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        width: 56px;
        height: 56px;
      }
    }
  }

  .description {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: ${spacing.small};
    font-size: ${fontSizes.s};

    .video-label {
      color: ${colors.gray4};
      margin-right: ${spacing.medium};
    }

    .video-title {
      line-height: ${lineHeights.s};
      margin-top: 1px;
    }
  }
`;
