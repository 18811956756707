import styled from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';

export const PagerStyled = styled.div`
  margin-top: ${spacing.regular};

  .PagerInner {
    max-width: 250px;
    margin: 0 auto;
  }

  .Pager {
    margin-bottom: ${spacing.small};
    text-align: center;
    font-size: ${fontSizes.s};
    letter-spacing: 0.1rem;
  }

  .Gauge {
    margin: 0 0 ${spacing.regular} 0;
    width: 100%;
  }

  .Buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      text-align: center;
      justify-content: center;
    }
  }
`;
