import { Component } from 'react';

import { Paragraph } from '../Paragraph/Paragraph';
import { TextButton } from '../TextButton/TextButton';

import { SlideInStyled } from './SlideIn.styled';
interface SlideInProps {
  isOpen: boolean;
  toggle: (...args: any[]) => any;
  headerText?: string;
  headerExtra?: string;
  headerIcon?: string;
  headerClick?: (...args: any[]) => any;
  clearText?: string;
  clearAll?: (...args: any[]) => any;
  padContent?: boolean;
  isMenu?: boolean;
  isCart?: boolean;
  isFilter?: boolean;
  fromLeft?: boolean;
  fromTop?: boolean;
  small?: boolean;
  urlToPartnerPortal?: string;
  partnerLabel?: string;
  iconLeft?: boolean;
  isSearch?: boolean;
  lastType?: string;
  noHeader?: boolean;
  children?: React.ReactNode;
  itemLength?: number;
}
export class SlideIn extends Component<SlideInProps> {
  node: HTMLElement | null = null;
  setNode: React.LegacyRef<HTMLDivElement> = (element) => {
    this.node = element;
  };

  constructor(props: SlideInProps) {
    super(props);
    this.node = null;
    this.setNode = (element) => {
      this.node = element;
    };
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.handleOnKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.handleOnKeyDown, false);
  }

  handleClick(event: MouseEvent) {
    if (this.node === event.target) {
      this.props.toggle();
    }
  }

  handleOnKeyDown(event: KeyboardEvent) {
    // esc key
    if (event.keyCode === 27) {
      if (this.props.isOpen) {
        this.props.toggle();
      }
    }
  }

  render() {
    const {
      isOpen,
      toggle,
      children,
      headerText,
      headerExtra,
      clearText,
      clearAll,
      headerIcon,
      isMenu,
      urlToPartnerPortal,
      partnerLabel,
      itemLength,
      headerClick,
      iconLeft,
      lastType,
      noHeader,
    } = {
      ...this.props,
    };
    const renderSlideInHeader = () => (
      <div className="SlideInHeader">
        <div className={isMenu ? 'HeaderText isMenu' : 'HeaderText'}>
          {isMenu
            ? (
              <>
                <svg
                  className="HeaderIcon Close"
                  onClick={(e) => toggle(e)}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M12.1317 11.4246L19.5563 4l.7072.70711-7.4247 7.42459 7.4247 7.4246-.7072.7072-7.4246-7.4247-7.42459 7.4247L4 19.5563l7.4246-7.4246L4 4.70711 4.70711 4l7.42459 7.4246z"
                  />
                </svg>
                {urlToPartnerPortal
                  ? (
                    <div className="PartnerLogin">
                      <Paragraph>
                        <a
                          href={urlToPartnerPortal}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="PartnerLoginLink"
                        >
                          {partnerLabel}
                          <img
                            src="/assets/gfx/hultafors_partner_login.svg"
                            alt="Hultafors partner login"
                            title="Hultafors partner login"
                            width={16}
                            height={16}
                          />
                        </a>
                      </Paragraph>
                    </div>
                    )
                  : (
                      ''
                    )}
              </>
              )
            : (
              <>
                {headerClick
                  ? (
                    <TextButton
                      iconLeft={iconLeft}
                      iconUrl={headerIcon}
                      onClick={headerClick}
                    >
                      <Paragraph styleType="body16Medium">{headerText}</Paragraph>
                    </TextButton>
                    )
                  : (
                    <>
                      {headerIcon && (
                        <img src={headerIcon} className="HeaderIcon" alt="" />
                      )}
                      <Paragraph styleType="body16Medium">{headerText}</Paragraph>
                      {headerExtra && (
                        <Paragraph
                          styleType="body16Medium"
                          className="Extras"
                        >
                          {`${headerExtra}`}
                        </Paragraph>
                      )}
                    </>
                    )}
              </>
              )}
        </div>

        <div className="SlideInExit">
          {clearAll && clearText && !!itemLength && (
            <button onClick={clearAll} className="Clear">
              <Paragraph styleType="body16">{clearText}</Paragraph>
              <img
                className="RemoveFilterIcon"
                src="/assets/gfx/trash_16.svg"
                alt="Remove filter"
                title="Remove filter"
              />
            </button>
          )}
        </div>
      </div>
    );
    return (
      <SlideInStyled
        $fromTop={this.props.fromTop}
        $fromLeft={this.props.fromLeft}
        $small={this.props.small}
        $isSearch={this.props.isSearch}
        $isFilter={this.props.isFilter}
        $padContent={this.props.padContent}
      >
        <div
          className={`SlideInBackground ${isOpen ? 'active' : ''}`}
          ref={this.setNode}
        >
          <div
            className={`SlideInInside ${isOpen ? 'active' : ''} ${lastType}`}
          >
            {!noHeader && renderSlideInHeader()}
            <div
              className={
                this.props.isCart ? 'SlideInBody isCart' : 'SlideInBody'
              }
            >
              {children}
            </div>
          </div>
        </div>
      </SlideInStyled>
    );
  }
}
