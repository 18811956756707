import { BoxStyled } from './Box.styled';

interface BoxProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const Box: React.FC<BoxProps> = ({ className, style, children }) => {
  return (
    <BoxStyled className={className} style={style}>
      {children}
    </BoxStyled>
  );
};
