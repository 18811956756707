import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { fontFamilies } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';

import { Paragraph } from '../Paragraph/Paragraph';

interface StyledProductProps {
  $isSmall?: boolean;
  $noHover?: boolean;
  $inFavorites?: boolean;
}

export const ProductStyled = styled.div<StyledProductProps>`
  position: relative;
  margin-bottom: ${({ $isSmall, $inFavorites }) =>
    $isSmall
      ? spacing.regular
      : $inFavorites
      ? spacing.medium
      : spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    &:not(.RelatedProduct) {
      margin-bottom: ${({ $isSmall, $inFavorites }) =>
        $isSmall && $inFavorites
          ? spacing.medium
          : $isSmall
          ? spacing.regular
          : spacing.medium};
    }
  }

  .productWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ProductImage {
    height: 100%;
    position: relative;
    background-color: #f7f7f7;

    &::before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('/assets/gfx/noImage.svg') no-repeat center;
      color: transparent;
      z-index: 1;
    }
  }

  .ProductIconWrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .ProductIcon {
    width: 24px;
    height: 24px;
    margin-right: ${spacing.small};
    margin-bottom: ${spacing.small};
  }

  .ProductInfo {
    padding: ${spacing.xsmall} 0 0 ${spacing.small};
    position: relative;

    .ProductName {
      display: block;
      color: ${colors.black};
      transition: 0.2s ease;
      font-size: ${fontSizes.body};
      line-height: ${lineHeights.body};
      font-weight: ${fontWeights.fontRegular};

      a {
        color: ${colors.black};
      }
    }
  }

  .ProductPrice {
    margin: 0;
    margin-top: ${spacing.xsmall};
    margin-bottom: ${spacing.small};
    transition: 0.2s ease;
    display: flex;
    align-items: baseline;

    .rrp {
      color: ${colors.gray1};
      margin-left: 8px;
    }

    p {
      margin: 0;
    }
  }

  .USP {
    ul {
      padding: 0;
      margin: 0;
      margin-top: 12px;
      list-style: initial;
      padding-left: 1em;

      li {
        font-size: ${fontSizes.body14};
        line-height: ${lineHeights.body12};
        font-weight: ${fontWeights.fontRegular};
        font-family: ${fontFamilies.fontRegular};
        color: ${colors.black};
      }
    }
  }

  .Favorite {
    position: absolute;
    top: ${spacing.xsmall};
    right: ${spacing.xsmall};
    display: flex;

    svg {
      position: initial;
    }

    &.InFavorites {
      top: -${spacing.xsmall};
      right: -${spacing.xsmall};
    }
  }

  .Badge {
    position: absolute;
    left: 0;

    &.TopLeft {
      top: ${spacing.xsmall};
    }

    &.BottomLeft {
      bottom: ${spacing.xsmall};
    }
  }

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    ${({ $noHover }) =>
      !$noHover
      && css`
        &:hover {
          .ImageWrapper img {
            transform: scale(1.05);
          }
        }
      `}
  }
`;

export const EsdImg = styled.img`
  position: absolute;
  z-index: 2;
  right: ${spacing.small};
  bottom: ${spacing.small};
  width: 32px;
  height: auto;
`;

export const New = styled(Paragraph)`
  position: absolute;
  z-index: 2;
  left: ${spacing.small};
  top: ${spacing.xsmall};
  margin: 0;
`;
