import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/toeguard/helpers';

import { GridChild as GridChildBase } from '../GridChild/GridChild';
import { Image as ImpImage } from '../Image/Image';

export const BigPlugStyled = styled.div`
  padding: 0 ${spacing.small};
  margin: ${spacing.medium} 0;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0 ${spacing.medium};
    margin: ${spacing.large} 0;
  }

  .ContentWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: ${spacing.medium} 0;
    padding: 0 ${spacing.regular};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding: 0 ${spacing.medium};
    }

    @media screen and (min-width: ${breakpoints.desktop}) {
      width: 75%;
      margin: 0;
    }

    h2 {
      padding-bottom: ${spacing.regular};
      color: ${colors.white};
    }
  }
`;

export const GridChild = styled(GridChildBase)<{ $greyBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ $greyBackground }) =>
    $greyBackground ? colors.gray4 : colors.black};
`;

export const Image = styled(ImpImage)`
  width: 100%;
  height: auto;
`;
