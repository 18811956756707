import { Fragment, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';

import { useGlobal } from '@hultafors/toeguard/hooks';
import {
  MenuItemFragment,
  SubMenuFragment,
  SubMenuItemFragment,
} from '@hultafors/toeguard/types';

import { AutoCompleteSearchBox } from '../AutoCompleteSearchBox/AutoCompleteSearchBox';
import { Favorites } from '../Favorites/Favorites';
import { Menu } from '../Menu/Menu';
import { NavItem } from '../NavItem/NavItem';
import { SlideInTop } from '../SlideInTop/SlideInTop';

import {
  Backdrop,
  Container,
  Flex,
  IconItem,
  IconLink,
  InnerDrop,
  Logo,
  LogoLink,
  MenuDrop,
  MenuIcon,
  MenuItem,
  MenuItemButton,
  NavButton,
  NavContainer,
  SecondaryNav,
  SubMenuItem,
  TransformWrapper,
} from './Nav.styled';

export interface OpenSettings {
  favorites: boolean;
  search: boolean;
  mobileMenu: boolean;
}
export type OpenSetting = keyof OpenSettings;

const defaultOpenSettings: OpenSettings = {
  favorites: false,
  search: false,
  mobileMenu: false,
};

export const Nav: React.FC = () => {
  const { global, menu } = useGlobal();
  const menuItems: MenuItemFragment[] = menu.menuItems ?? [];
  const router = useRouter();
  const [subMenuShowing, setSubMenuShowing] = useState<string>('');
  const [isOpen, setIsOpen] = useState<OpenSettings>(defaultOpenSettings);

  useEffect(() => {
    setSubMenuShowing('');
  }, [router.asPath]);

  const toggleMenu = (key: OpenSetting) => {
    const open = !isOpen[key];
    setSubMenuShowing('');
    setIsOpen({ ...defaultOpenSettings, [key]: open });
  };

  const menuItemsMapper = (menuItem: MenuItemFragment) => {
    if (!menuItem.submenu && !menuItem.url) {
      return null;
    }
    const key = `Nav-MenuItem=${menuItem.id}`;

    if (menuItem.submenu) {
      const active
        = router.asPath.includes('/guides')
        || !!(
          menuItem.submenu?.title && subMenuShowing === menuItem.submenu?.title
        );
      return (
        <MenuItemButton
          key={key}
          $active={active}
          onClick={() => {
            setIsOpen(defaultOpenSettings);
            setSubMenuShowing(
              subMenuShowing === menuItem.submenu?.title
                ? ''
                : menuItem.submenu?.title ?? '',
            );
          }}
        >
          {menuItem.title}
        </MenuItemButton>
      );
    }
    let active = false;
    const firstPart = menuItem.url?.split('/').filter(Boolean).shift();
    if (
      router.asPath !== '/'
      && firstPart
      && router.asPath.startsWith(`/${firstPart}`)
    ) {
      active = true;
    }
    return (
      <MenuItem key={key} href={menuItem.url ?? ''} $active={active}>
        {menuItem.title}
      </MenuItem>
    );
  };

  const subMenuMapper = (subMenuItem: SubMenuItemFragment) => {
    return (
      <SubMenuItem
        key={`submenu-item-${subMenuItem.title}-${subMenuItem.id}`}
        href={`/guides/${subMenuItem.pageLink?.slug}`}
      >
        {subMenuItem.title}
      </SubMenuItem>
    );
  };

  const subMenus: SubMenuFragment[] = useMemo(() => {
    return (
      menuItems
        .filter(({ submenu }) => !!submenu)
        .map(({ submenu }) => submenu as SubMenuFragment)
        .filter(Boolean) ?? []
    );
  }, [menuItems]);

  const menuItemMapper2 = (subMenu: SubMenuFragment) => {
    const show = !!subMenu.title && subMenuShowing === subMenu.title;
    return (
      <Fragment key={`Nav-submenu-${subMenu.id}`}>
        <Backdrop $show={show} />
        <MenuDrop $show={show}>
          <InnerDrop>{subMenu.menuItems.map(subMenuMapper)}</InnerDrop>
        </MenuDrop>
      </Fragment>
    );
  };

  return (
    <NavContainer>
      <TransformWrapper>
        <Container>
          <MenuIcon
            src="/assets/gfx/hamburger_24.svg"
            width={24}
            height={55}
            onClick={() => toggleMenu('mobileMenu')}
            alt="menu"
          />
          <Flex>
            <LogoLink href="/">
              <Logo
                src="/assets/gfx/toe_guard_logo.svg"
                width={131}
                height={24}
                alt="Toeguard logo"
              />
            </LogoLink>
            <Flex $desktop>{menuItems?.map(menuItemsMapper)}</Flex>
          </Flex>
          <SecondaryNav>
            <NavButton
              $desktop
              aria-label="search"
              onClick={() => toggleMenu('search')}
            >
              <IconItem
                src="/assets/gfx/search.svg"
                alt="search"
                width={24}
                height={24}
                id="toggleSearch"
              />
            </NavButton>
            <IconItem
              as={({ ...rest }: any) => <NavItem {...rest} />}
              isFavorite
              toggle={() => toggleMenu('favorites')}
            />
            <IconLink
              href={global?.urlToPartnerPortal || ''}
              aria-label="Partner Portal"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconItem
                src="/assets/gfx/partner_login_24.svg"
                alt="partner login"
                width={16}
                height={16}
              />
            </IconLink>
          </SecondaryNav>
        </Container>
      </TransformWrapper>
      {subMenus?.map(menuItemMapper2)}
      {isOpen.favorites && (
        <Favorites
          isOpen={isOpen.favorites}
          toggleMenu={() => toggleMenu('favorites')}
          header={global?.favorites ?? ''}
          clearText={global?.clearAll ?? ''}
          badgeText={global?.new ?? ''}
          rrpLabel={global?.rrpLabel ?? ''}
          favoritesEmpty={global?.noFavorites ?? ''}
          isSmall
        />
      )}
      {isOpen.search && (
        <Backdrop className={`${isOpen.search ? 'show' : ''}`} />
      )}
      {isOpen.search && (
        <SlideInTop
          open={isOpen.search}
          close={() => {
            setIsOpen((prevIsOpen) => ({ ...prevIsOpen, search: false }));
          }}
        >
          <AutoCompleteSearchBox
            className="SearchWrapper"
            isOpen={isOpen.search}
            focusInput
          />
        </SlideInTop>
      )}
      {isOpen.mobileMenu && (
        <Menu
          isOpen={isOpen.mobileMenu}
          toggleMenu={() => {
            setSubMenuShowing('');
            toggleMenu('mobileMenu');
          }}
          headerText={global?.menu ?? ''}
          menu={menuItems}
          subMenuShowing={subMenuShowing}
          setSubMenuShowing={setSubMenuShowing}
          urlToPartnerPortal={global?.urlToPartnerPortal ?? ''}
          partnerLoginLabel={global?.partnerPortalLabel ?? ''}
        />
      )}
    </NavContainer>
  );
};
