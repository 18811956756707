import { Button } from '../Button/Button';
import { Paragraph } from '../Paragraph/Paragraph';
import { TextButton } from '../TextButton/TextButton';

import { PagerStyled } from './Pager.styled';

interface PagerProps {
  loadMore(): void;
  showAll(): void;
  pagerData: any;
  loading?: boolean;
  showMoreText?: string | null;
  showAllText?: string | null;
  ofText?: string | null;
  productsText?: string | null;
}

export const Pager: React.FC<PagerProps> = ({
  showMoreText,
  pagerData,
  loadMore,
  showAll,
  showAllText,
  loading,
}) => {
  if (!pagerData) {
    return null;
  }
  const { itemCount, pageNumber, pageCount } = pagerData;
  if (!itemCount || pageNumber >= pageCount) {
    return null;
  }
  return (
    <PagerStyled>
      <div className="PagerInner">
        <div className="Buttons">
          <Button
            onClick={loadMore}
            center
            className={loading ? 'Loading Outline' : 'Outline'}
          >
            {showMoreText}
          </Button>
          <TextButton className="TextButton" onClick={showAll}>
            <Paragraph styleType="body14Medium">{showAllText}</Paragraph>
          </TextButton>
        </div>
      </div>
    </PagerStyled>
  );
};
