import { ToeguardProduct } from '@hultafors/toeguard/api';
import {
  createDetailPageUrl,
  isProductFavorite,
} from '@hultafors/toeguard/helpers';
import { useFavorites } from '@hultafors/toeguard/hooks';

import { Favorite } from '../Favorite/Favorite';
import { Paragraph } from '../Paragraph/Paragraph';
import { ProductImage } from '../ProductImage/ProductImage';
import { RouterLink } from '../RouterLink/RouterLink';

import { EsdImg, New, ProductStyled } from './Product.styled';
interface ProductProps {
  product: ToeguardProduct;
  badgeText: string;
  inFavorites?: boolean;
  isFavorite?: boolean;
  isSmall?: boolean;
  rrpLabel?: string | null;
  noHover?: boolean;
  toggleMenu?: (...args: any[]) => any;
  routerState?: object;
  onClick?: (...args: any[]) => any;
  className?: string;
}
export const Product: React.FC<ProductProps> = ({
  product,
  badgeText,
  inFavorites,
  toggleMenu,
  routerState,
  onClick,
  isSmall,
  noHover,
  rrpLabel,
  className,
}) => {
  const { toggleItem, favorites } = useFavorites();
  const isArray = (array: any) => {
    return array && Array.isArray(array) && array.length > 0;
  };
  const handleOnClick = () => {
    if (onClick) {
      onClick(product.productId);
    }
    toggleMenu?.();
  };
  return (
    <ProductStyled
      className={className}
      $isSmall={isSmall}
      $noHover={noHover}
      $inFavorites={inFavorites}
    >
      <div className={`productWrapper ${product.productId}`}>
        <div className="ProductImage">
          <ProductImage
            product={product}
            onClick={handleOnClick}
            routerState={routerState}
          />
          <Favorite
            toggleFavorite={toggleItem}
            product={product}
            isFavorite={isProductFavorite(product.sku, favorites)}
            inFavorites={inFavorites}
          />
          {product.isEsd && (
            <EsdImg
              src="/assets/gfx/ESD_logotype.svg"
              width={32}
              height={32}
              alt="ESD"
            />
          )}
          {product.isNew && <New styleType="body12">{badgeText}</New>}

          {isArray(product.icons) && (
            <span className="ProductIconWrapper">
              {product.icons.slice(0, 3).map((icon, index) => {
                return (
                  <img
                    className="ProductIcon"
                    key={`icon${index}`}
                    src={icon.image.url}
                    alt={icon.description}
                    title={icon.text}
                  />
                );
              })}
            </span>
          )}
        </div>

        <div className="ProductInfo">
          <RouterLink
            className="ProductName"
            to={createDetailPageUrl(product)}
            onClick={handleOnClick}
          >
            <Paragraph styleType="body14Medium">{product.name}</Paragraph>
          </RouterLink>
          {!!product.price?.value && (
            <div className="ProductPrice">
              <Paragraph styleType="body14">{`${product.price.currency} ${product.price.value}`}</Paragraph>
              <Paragraph styleType="body10" className="rrp">
                {rrpLabel}
                {' '}
              </Paragraph>
            </div>
          )}

          {product.usp && product.usp.length > 0 && (
            <div className="USP">
              <ul>
                {product.usp.map((item, index) => (
                  <li key={`usp-l-${index}`}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </ProductStyled>
  );
};
