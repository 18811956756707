import styled, { css, CSSProperties } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

export type GridChildColumnSpanProp =
  | CSSProperties['gridColumn']
  | ColumnSpan[];

interface StyledGridChildProps {
  $align?: CSSProperties['justifySelf'];
  $alignSelf?: CSSProperties['alignSelf'];
  $padding?: CSSProperties['padding'];
  $rowStart?: CSSProperties['gridRowStart'];
  $rowSpan?: CSSProperties['gridRowEnd'];
  $columnSpan?: GridChildColumnSpanProp;
}

interface ColumnSpan {
  start?: CSSProperties['gridColumnStart'];
  columns?: CSSProperties['gridColumnEnd'];
  display?: CSSProperties['display'];
  breakpoint?: Breakpoint;
}

const getColumnSpan = ({ $columnSpan }: StyledGridChildProps) => {
  if (!Array.isArray($columnSpan)) {
    return css`
      grid-column: ${$columnSpan || '1 / -1'};
    `;
  }
  return $columnSpan.map(({ breakpoint, display, start, columns }) => {
    const gridStart = start ? `${start} /` : '';
    const end = columns ? `span ${columns}` : -1;
    const gridColumn = `${gridStart} ${end}`;
    const value = css`
      display: ${display ?? 'initial'};
      grid-column: ${gridColumn};
    `;
    if (!breakpoint) {
      return value;
    }
    return css`
      @media all and (min-width: ${breakpoints[breakpoint]}) {
        ${value}
      }
    `;
  });
};

export const StyledGridChild = styled.div<StyledGridChildProps>`
  place-self: ${({ $alignSelf }) => $alignSelf} ${({ $align }) => $align};
  padding: ${({ $padding }) => $padding};
  grid-row: ${({ $rowStart }) => $rowStart && `${$rowStart}`} /
    ${({ $rowSpan }) => $rowSpan && `span ${$rowSpan}`};

  ${getColumnSpan};
`;
