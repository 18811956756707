import styled, { css } from 'styled-components';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
import { fontWeights } from '@hultafors/toeguard/helpers';
import { lineHeights } from '@hultafors/toeguard/helpers';

export type ButtonVariant = 'primary' | 'secondary' | 'rounded';

interface StyledButtonProps {
  $variant?: ButtonVariant;
}

const getColor = ({ $variant }: StyledButtonProps) => {
  switch ($variant) {
    case 'primary':
      return css`
        background-color: ${colors.toeLime};
        color: ${colors.black};

        &:hover {
          background-color: ${colors.toeLimeHover};
        }
      `;
    case 'secondary':
      return css`
        background-color: ${colors.gray5};
        color: ${colors.black};

        &:hover {
          background-color: ${colors.gray4};
        }
      `;
    case 'rounded':
      return css`
        background-color: ${colors.black};
        color: ${colors.white};
        border-radius: 100px;

        &:hover {
          background-color: ${colors.blackHover};
        }
      `;
    default:
      return css`
        background-color: ${colors.toeLime};
        color: ${colors.black};

        &:hover {
          background-color: ${colors.toeLimeHover};
        }
      `;
  }
};

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${spacing.medium};
  min-width: 147px;
  width: fit-content;
  text-decoration: none;
  padding: 17px 40px;
  cursor: pointer;
  border: none;
  transition: 0.2s;
  outline: none;

  /* Text style */
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body16};
  font-weight: ${fontWeights.fontMedium};

  ${getColor};
`;
