import Markdown from 'markdown-to-jsx';

import { Grid } from '../Grid/Grid';
import { GridChild } from '../GridChild/GridChild';
import { HTag } from '../HTag/HTag';

import { StyledBulletList } from './BulletList.styled';
interface BulletListProps {
  title?: string | null;
  body?: string | null;
  isBig?: boolean;
}
export const BulletList: React.FC<BulletListProps> = ({
  title,
  body,
  isBig,
}) => {
  const options = { forceBlock: true };
  const className = isBig ? 'markdown-wrapper big' : 'markdown-wrapper';
  return (
    <StyledBulletList $isBig={isBig}>
      <Grid>
        <GridChild
          columnSpan={[
            { columns: 4 },
            { breakpoint: 'mobileMax', columns: 8, start: 3 },
            { breakpoint: 'desktop', columns: 6, start: 4 },
          ]}
        >
          <div className="content">
            {title && (
              <HTag type="h2" styleType={isBig ? 'header3' : 'header4'}>
                {title}
              </HTag>
            )}

            <Markdown options={options} className={className}>
              {body ?? ''}
            </Markdown>
          </div>
        </GridChild>
      </Grid>
    </StyledBulletList>
  );
};
