import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';

export const StyledDualImage = styled.div`
  margin: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    margin: ${spacing.medium} 0;
  }

  .ImageGridChild {
    max-height: 800px;
    overflow: hidden;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      max-height: 500px;
    }
  }

  img {
    min-width: 100%;
    max-width: 100%;
    height: auto;
  }
`;
