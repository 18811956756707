interface IconProps {
  icon?: string | React.ReactNode;
  style?: React.CSSProperties;
}
export const Icon: React.FC<IconProps> = ({ icon, style }) => {
  return typeof icon === 'string'
    ? (
      <img src={icon} alt="icon" style={style} />
      )
    : (
      <>icon</>
      );
};
