import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/toeguard/helpers';
import { colors } from '@hultafors/toeguard/helpers';

export const ExploreMoreSectionStyled = styled.div`
  border-block-start: thin solid ${colors.gray4};
  padding-block: ${spacing.medium};
  padding-inline: ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: ${spacing.large};
    padding-inline: ${spacing.medium};
  }

  &.NoBorder {
    border-block-start: none;
    padding: 0;
    margin-block: ${spacing.medium};
    margin-inline: ${spacing.small};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block: ${spacing.large};
      margin-inline: ${spacing.medium};
    }
  }

  h2 {
    margin-block-end: ${spacing.regular};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-block-end: ${spacing.medium};
    }
  }
`;
