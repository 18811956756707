import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors } from '@hultafors/toeguard/helpers';
import { spacing } from '@hultafors/toeguard/helpers';
import { ImageFragment } from '@hultafors/toeguard/types';

interface HeroStyledProps {
  $image?: ImageFragment | null;
  $imageMobile?: ImageFragment | null;
}

export const HeroStyled = styled.div<HeroStyledProps>`
  @media screen and (min-width: ${breakpoints.desktop}) {
    margin: 0 ${spacing.medium} 0 ${spacing.medium};
  }

  .ImageWrapper {
    height: 568px;
    width: 100%;
    background-image: ${({ $imageMobile, $image }) =>
      $imageMobile?.url
        ? `url(${$imageMobile.url}?w=800)`
        : $image?.url
        ? `url(${$image?.url}?w=1332)`
        : ''};
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      height: 720px;
      background-image: ${({ $image }) =>
        $image ? `url(${$image.url}?w=1920)` : ''};
    }
  }

  .Title {
    color: ${colors.white};
    text-align: left;
  }

  .HeroContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-left: ${spacing.small};
    padding-right: ${spacing.small};

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .CenterWrapper {
    width: 100%;
    position: relative;
  }

  .HeroButtonWrapper {
    position: absolute;
    width: 100%;

    a:first-child {
      margin-bottom: ${spacing.small};
    }

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: ${spacing.medium};
    text-align: center;
    ${({ $image }) =>
      !$image
      && css`
        a {
          border: 1px solid ${colors.black};
          color: ${colors.black};

          &:hover {
            background-color: ${colors.black};
            color: ${colors.white};
          }
        }
      `}

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      margin-top: ${spacing.medium};
    }
  }

  .InnerButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-width: 120px;
    max-width: 100%;
  }
`;
