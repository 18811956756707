import { FC, ReactNode, useRef } from 'react';

import { useClickAway } from 'react-use';

import { SlideInTopContainer } from './SlideInTop.styled';

export interface SlideInTopProps {
  children?: ReactNode;
  open?: boolean;
  close?(): void;
}

export const SlideInTop: FC<SlideInTopProps> = ({ open, close, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  // Close on click outside
  useClickAway(ref, (event) => {
    event.preventDefault();
    close?.();
  });

  return (
    <SlideInTopContainer ref={ref} $open={open}>
      {children}
    </SlideInTopContainer>
  );
};
