import { useEffect, useState } from 'react';

import Head from 'next/head';
interface HeadComponentProps {
  description?: string;
  image?: {
    alt?: string;
    url?: string;
    width?: number;
    height?: number;
  };
  lang?: string;
  prerenderStatusCode?: number;
  title?: string;
  type?: string;
  url: string;
  canonicalBase?: string;
}
export const HeadComponent: React.FC<HeadComponentProps> = ({
  description,
  image,

  prerenderStatusCode,
  title,
  type = 'website',
  url,
}) => {
  const [pageUrl, setPageUrl] = useState({ href: 'asd.asd' });
  const [imageUrl, setImageUrl] = useState({ href: 'asd.asd' });
  useEffect(() => {
    // config.application.baseUrl will be a fallback
    // if an URL scheme (protocol, hostname and optional port) is provided as url and/or image.url
    // it will not be overriden by the config one (useful to define external links)
    // const PAGE_URL = new URL(url, process.env.APP_BASE_URL);
    // const IMAGE_URL = image && image.url ? new URL(image.url, process.env.APP_BASE_URL) : null;
    const BASE_URL = window.location.origin;
    const PAGE_URL = new URL(url, BASE_URL);
    const IMAGE_URL = image && image.url ? new URL(image.url, BASE_URL) : null;
    setPageUrl(PAGE_URL);
    if (IMAGE_URL) {
      setImageUrl(IMAGE_URL);
    }
  }, [image, url]);
  return (
    <Head>
      <link rel="canonical" content={pageUrl.href} />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={pageUrl.href} />

      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl.href} />}
      {image && image.width && (
        <meta property="og:image:width" content={`${image.width}`} />
      )}
      {image && image.height && (
        <meta property="og:image:height" content={`${image.height}`} />
      )}
      <meta name="twitter:card" content="summary" />

      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {imageUrl && <meta property="twitter:image" content={imageUrl.href} />}
      {image && image.alt && (
        <meta property="twitter:image:alt" content={image.alt} />
      )}
      {prerenderStatusCode && (
        <meta name="prerender-status-code" content={`${prerenderStatusCode}`} />
      )}
    </Head>
  );
};
