import { UrlObject } from 'url';

import Link from 'next/link';

import { ButtonVariant, StyledButton } from './Button.styled';

interface ButtonProps {
  loading?: boolean;
  variant?: ButtonVariant;
  to?: string | null | UrlObject;
  onClick?(...args: any[]): void;
  children?: React.ReactNode;
  center?: boolean;
  className?: string;
}

export const Button: React.FC<ButtonProps> = ({
  loading,
  variant = 'primary',
  to,
  onClick,
  children,
  className,
}) => {
  if (!to && !onClick) {
    return null;
  }
  if (!to) {
    return (
      <StyledButton className={className} $variant={variant} onClick={onClick}>
        {children}
      </StyledButton>
    );
  }
  const isExternal
    = !!(typeof to === 'string' && to?.startsWith('http'))
    || !!(typeof to !== 'string' && to.protocol);
  const target = isExternal ? '_blank' : undefined;
  const rel = isExternal ? 'noreferrer noopener' : undefined;

  return (
    <StyledButton
      as={Link}
      className={className}
      href={to}
      target={target}
      rel={rel}
      $variant={variant}
    >
      {children}
    </StyledButton>
  );
};
