import styled from 'styled-components';

import { spacing } from '@hultafors/toeguard/helpers';
import { fontSizes } from '@hultafors/toeguard/helpers';
export const StyledRetailersAdditionalSelections = styled.div`
  margin-top: ${spacing.regular};

  .RadiusSelectContainer {
    label {
      font-size: ${fontSizes.body12};
    }
  }
`;
